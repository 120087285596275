import React from 'react';
import { useTheme, makeStyles, IconButton } from '@material-ui/core';
import useMetadataUpdate from '../../hooks/useMetadataUpdate';
import { useQuery } from 'react-fetching-library';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 3,
    zIndex: 2000,
  },
}));

const EnvTopBar = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { query: test } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'GET',
        endpoint: `/test`,
      },
      false,
    ],
  );
  if (process.env.REACT_APP_ENV === 'production') {
    return null;
  }
  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: theme.colors[process.env.REACT_APP_ENV],
      }}
    >
      {/*<IconButton*/}
      {/*  onClick={async () => {*/}
      {/*    await test()*/}
      {/*  }}*/}
      {/*  size='small'*/}
      {/*>*/}
      {/*  {'401 TEST'}*/}
      {/*</IconButton>*/}
    </div>
  );
};

export default EnvTopBar;
