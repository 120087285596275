import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useMutation, useQuery } from 'react-fetching-library';
import { SortDirection } from 'react-virtualized';
import { get, isEmpty } from 'lodash';
import { ReactComponent as PrintIcon } from 'assets/icons/print_black.svg';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import RemoveDialog from 'components/Dialogs/RemoveDialog';
import useFetchList from 'hooks/useFetchList';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import showServerError from 'helpers/showError';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import useOrderMetadata from 'hooks/useOrderMetadata';
import { useDispatch, useSelector } from 'react-redux';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { NULL_QUERY_DELAY } from '../Shared/constants';
import ordersPlaceholderImage from '../../assets/placeholders/orders.svg';
import RefreshTableButton from '../Shared/RefreshTableButton';
import PatientActions from '../Patients/PatientActions';
import PatientModals from '../Patients/PatientModals';
import PatientAdditionalNode from '../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../Patients/PatientInfoBlock';
import {
  BILLINGS_FOR_PATIENT_FIELDS,
  FAKE_BILLING,
  FIELDS,
  GET_BILLINGS_COLUMNS,
} from './constants';
import  { downloadFile } from './helpers';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import getFakeDataList, { FAKE_TITLE_DATA } from '../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../redux_store/reducer/reducers/tableColumnsReducer';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

const useStyles = makeStyles(() => ({
  paper: {
    overflow: 'hidden',

    '@media print': {
      display: 'none',
    },
  },
}));

const BillingList = () => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const {
    user,
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const [isOpenRemoveDialog, setOpenRemoveDialog] = useState(false);
  const [filterStyle, setFilterStyle] = useState(false);
  const [openAssessmentModal, toggleAssessmentModal] = useState(false);
  const [openResourceModal, toggleResourceModal] = useState(false);
  const [modifiedItems, setModifiedItems] = useState();
  const [removingBill, setRemovingBill] = useState({});
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const [orderPatientUuid, setOrderPatientUuid] = useState(null); // trigger useOrderMetadata
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);

  const { loading: metadataLoading } = useOrderMetadata(
    orderPatientUuid,
    paramAccountUuid,
    () => {
      showServerError(dispatch, t('errors:noProvidersOnThisAccount'));
      setOrderPatientUuid(null);
    },
    {
      actionTitle: 'Edit patient',
      reorderUuid: 'newOrder',
      actionCallback: () => {
        togglePatientEditModal(true);
      },
      callback: () => {
        setOrderPatientUuid(null);
      },
    },
  );

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }/billings`;
  const {
    items,
    totalCount,
    totalAccountsCount,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: (isPatientPage ? BILLINGS_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {
      sortBy: 'reviewedTimestamp',
      sortDirection: SortDirection.DESC,
    },
  });

  const { query: queryRemove, loading: loadingRemove } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'DELETE',
        endpoint: `/accounts/${removingBill.accountUUID}/bills/${removingBill.uuid}`,
      },
      false,
    ],
  );

  const { filter } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);
  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  useEffect(() => {
    if (items) {
      const newModifiedItems = items.map((item) => ({
        ...item,
        completedTimestamp: getLocalizedDate(item.completedTimestamp),
        accountCustomIdentifier: item.accountCustomIdentifier
          ? item.accountCustomIdentifier
          : 'N/A',
        patientMrnNumber: !item.patientMrnNumber ? '-' : item.patientMrnNumber,
        reviewedTimestamp: getLocalizedDate(item.reviewedTimestamp),
        moreTitle: isPatientPage
          ? `${item.procedureName}`
          : `${item.patientFirstName} ${item.patientLastName}`,
        accountCustomTags: item.accountCustomTags ? item.accountCustomTags.map(item => getLocalizedLabel(
          CUSTOM_TAGS_OPTIONS,
          item,
        )) : [],
      }));
      setModifiedItems(newModifiedItems);
    }
  }, [items]);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const handleCloseRemoveDialog = () => {
    setOpenRemoveDialog(false);
    setRemovingBill({});
  };

  const handleRemove = () => {
    if (removingBill.uuid) {
      queryRemove().then(({ error, payload, status }) => {
        if (!error) {
          setIsSuccess(true);
          setTimeout(() => {
            setIsSuccess(false);
          }, 2000);
          reloadData();
          dispatch(
            enqueueSnackbar({
              message: t('notifications:billRemoved'),
              options: {
                variant: SNACKBAR_VARIANTS.success,
              },
            }),
          );
        } else {
          setIsFailed(true);
          setTimeout(() => {
            setIsFailed(false);
          }, 2000);
          const options = payload && {
            correlationUUID: payload.correlationUUID,
            userUUID: user.uuid,
          };
          switch (status) {
            case 404: {
              showServerError(dispatch, t('errors:billCouldNotFind'));
              break;
            }
            case 500: {
              switch (payload.errorCode) {
                case 1: {
                  showServerError(dispatch, t('errors:billCantBeRemoved'));
                  break;
                }
                default: {
                  showServerError(dispatch, '', options);
                  break;
                }
              }
              break;
            }
            default: {
              showServerError(dispatch, '', options);
              break;
            }
          }
        }
        setOpenRemoveDialog(false);
        setRemovingBill({});
      });
    }
  };

  const importFile = (bill) => ({
    method: 'GET',
    endpoint: `/accounts/${bill.accountUUID}/billings/${bill.uuid}/billing-report/pdf`,
    responseType: 'blob',
  });

  const { mutate: importMutate } = useMutation(importFile);

  const downloadBillingPDF = async (e, bill) => {
    const { payload, error, status } = await importMutate(bill);
    if (!error) {
      downloadFile(
        new Blob([payload]),
        `${bill.procedureName} - ${bill.patientLastName} ${bill.patientFirstName} ${bill.completedTimestamp}.pdf`);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:pdfSucceededSaved'),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    } else {
      switch (status) {
        case 422: {
          const obj = JSON.parse(await payload.text());
          Object.keys(obj.validationErrors).forEach(key =>
            showServerError(dispatch, obj.validationErrors[key]));
          break;
        }
        default: {
          showServerError(dispatch, t('errors:unknownError'));
          break;
        }
      }
    }
  };


  const redirectToEditBill = () =>
    // { uuid, accountUUID, patientUUID }
    {
      // setAppRoute({
      //   path: routes.patientBillEdit.path,
      //   params: {
      //     accountUuid: accountUUID,
      //     patientUuid: patientUUID,
      //     billUuid: uuid,
      //   },
      //   title: t('titles:editBill'),
      // });
    };

  const handleRowClick = (bill) => {
    redirectToEditBill(bill);
  };

  const reloadBillingsList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
      FAKE_TITLE_DATA
    : t('titles:billingReports');

  const isTableDataLoading = dataLoading || !modifiedItems;
  const FAKE_BILLING_LIST = getFakeDataList(FAKE_BILLING);
  return (
    <>
      {isPatientPage && (
        <PatientModals
          openPatientEditModal={openPatientEditModal}
          handleClosePatientEditModal={() => {
            togglePatientEditModal(false);
          }}
          openAssessmentModal={openAssessmentModal}
          handleCloseAssessmentModal={() => toggleAssessmentModal(false)}
          openResourceModal={openResourceModal}
          handleCloseResourceModal={() => toggleResourceModal(false)}
          reloadData={() => null}
        />
      )}

      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          <>
            {isPatientPage ? (
              <PatientActions
                isLoading={metadataLoading}
                toggleAssessmentModal={toggleAssessmentModal}
                toggleResourceModal={toggleResourceModal}
                togglePatientEditModal={togglePatientEditModal}
                setOrderPatientUuid={setOrderPatientUuid}
              />
            ) : (
              <RefreshTableButton
                reloadData={reloadBillingsList}
                isLoading={dataLoading}
              />
            )}
          </>
        }
        additionalNode={
          isPatientPage && (
            <PatientAdditionalNode
              activeItemNumber={3}
              modifiedData={modifiedItems}
              totalCount={totalCount}
              reloadData={reloadBillingsList}
              dataLoading={dataLoading}
              historyTitle={t('tables:billingHistory')}
            />
          )
        }
      >
        <VirtualizedTable
          tableKey={paramAccountUuid ? TABLE_KEYS.billingDS : TABLE_KEYS.billing}
          hasColumnsSelect={true}
          fakeRows={FAKE_BILLING_LIST}
          rowCount={totalCount}
          rows={modifiedItems}
          columns={GET_BILLINGS_COLUMNS(isPatientPage, paramAccountUuid, totalAccountsCount)}
          onRowClick={handleRowClick}
          FetchProps={{ ...restFetchProps }}
          onDataLoading={isTableDataLoading}
          currentAccountPermissions={currentAccountPermissions}
          actionProps={{
            actions: [
              {
                key: '1',
                icon: PrintIcon,
                onActionClick: downloadBillingPDF,
                children: t('btn:downloadPDF'),
              },
            ],
          }}
          placeholder={
            <TablePlaceholder
              image={ordersPlaceholderImage}
              itemsNotFoundText={t('placeholders:noItemsFound', {
                item: t('titles:bills'),
              })}
              message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
                item: t('titles:bill'),
              })}
            />
          }
        />
        <RemoveDialog
          open={isOpenRemoveDialog}
          onClose={handleCloseRemoveDialog}
          onSubmit={handleRemove}
          title={t('dialogs:confirmRemove', { name: t('titles:bill') })}
          isLoading={loadingRemove}
          isSuccess={isSuccess}
          isFailed={isFailed}
        />
      </ContentWrapper>
    </>
  );
};

export default BillingList;
