import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { FormattedPhone } from 'components/Shared/FormattedPhone';
import FormikTextField from 'components/FormikFields/FormikTextField';
import cx from 'classnames';
import SendingOptions from './SendingOptions';

const useStyles = makeStyles((theme) => ({
  secondaryTitle: {
    fontSize: 15,
    fontWeight: 400,
    textAlign: 'center',
    color: theme.colors.blue2,
    opacity: 0.5,
    padding: 0,
    marginBottom: 20,
  },
  formError: {
    textAlign: 'center',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  checkbox: {
    marginLeft: 0,
    marginBottom: 20,
  },
  divider: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  dividerSpacing: {
    marginTop: 0,
  },
  radio: {
    color: theme.colors.grey3,
  },
  checkedRadio: {
    color: theme.colors.lightBlue,
  },
  roleLabel: {
    margin: theme.spacing(0, 0, 1.875, 0),
    width: 125,
  },
  radioContainer: {
    width: 210,
  },
  pr2: {
    paddingRight: theme.spacing(3),
  },
  hideCheckbox: {
    maxHeight: 0,
    transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
    overflow: 'hidden',
    opacity: 0,
  },
  showCheckbox: {
    maxHeight: 100,
    height: 70,
    opacity: 1,
    transition: 'max-height 0.5s ease-in, opacity 0.5s ease-in',
  },
  checkboxContainer: {
    marginBottom: 30,
    padding: '0 !important',
  },
}));

const AssessmentForm = ({
                          backendErrors,
                          actions,
                          subtitle,
                          values,
                          initialValues,
                          hideSendingOptions,
                          patientUUID,
                          accountUuid,
                        }) => {
  const [hasEmailDiff, setHasEmailDiff] = useState(
    values.email !== initialValues.email && values.email,
  );
  const [hasPhoneDiff, setHasPhoneDiff] = useState(
    values.phone !== initialValues.phone && values.phone,
  );

  useEffect(() => {
    setHasEmailDiff(values.email !== initialValues.email && values.email);
    setHasPhoneDiff(values.phone !== initialValues.phone && values.phone);
  }, [values.email, values.phone]);

  const classes = useStyles();
  const { t } = useTranslation(['forms', 'errors', 'notifications']);

  return (
    <Grid container direction='column' justify='center' spacing={3}>
      <Grid item>
        <Typography className={classes.secondaryTitle}>{subtitle}</Typography>
      </Grid>
      <Grid container direction='row' justify='center' spacing={4}>
        <Grid item xs={4} sm={4} md={4} direction='column'>
          <FormikTextField
            type='text'
            name='email'
            label={t('forms:email')}
            backendError={
              backendErrors && (backendErrors.email ||
                backendErrors.Email ||
                backendErrors.sendInviteToPatientEmail ||
                (backendErrors[''] && ' '))
            }
          />
          <Grid
            className={cx(classes.hideCheckbox, {
              [classes.showCheckbox]: hasEmailDiff,
            })}
          >
            <Field name='updatePatientRecordEmail' type='checkbox'>
              {({ field, form }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color='primary'
                      onChange={(e, checked) => {
                        form.setFieldValue(field.name, checked);
                      }}
                      disabled={!hasEmailDiff}
                    />
                  }
                  className={classes.checkbox}
                  label={t('forms:updatePatientRecordEmail')}
                />
              )}
            </Field>
          </Grid>
          <FormikTextField
            type='tel'
            name='phone'
            label={t('forms:phone')}
            backendError={
              backendErrors && (backendErrors.phone ||
                backendErrors.Phone ||
                backendErrors.sendInviteToPatientPhone ||
                (backendErrors[''] && ' '))
            }
            InputProps={{
              inputComponent: FormattedPhone,
            }}
          />

          <Grid
            item
            className={cx(classes.hideCheckbox, {
              [classes.showCheckbox]: hasPhoneDiff,
            })}
          >
            <Field name='updatePatientRecordPhone' type='checkbox'>
              {({ field, form }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color='primary'
                      onChange={(e, checked) => {
                        form.setFieldValue(field.name, checked);
                      }}
                      disabled={!hasPhoneDiff}
                    />
                  }
                  className={classes.checkbox}
                  label={t('forms:updatePatientRecordPhone')}
                />
              )}
            </Field>
          </Grid>
        </Grid>
        {
          !hideSendingOptions &&
          <Grid item xs={4} sm={4} md={4} direction='column'>
            <SendingOptions
              sendingDataError={backendErrors && backendErrors.sendingDataError}
              values={values}
              patientUUID={patientUUID}
              accountUuid={accountUuid}
            />
          </Grid>
        }
      </Grid>
      <div className={classes.formError}>
        <FormHelperText error>{backendErrors && backendErrors['']}</FormHelperText>
      </div>
      {actions}
    </Grid>
  );
};

AssessmentForm.propTypes = {
  subtitle: PropTypes.string.isRequired,
  actions: PropTypes.node,
  hideSendingOptions: PropTypes.bool,
  values: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  initialValues: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
  }).isRequired,
  backendErrors: PropTypes.shape({
    email: PropTypes.string,
    Email: PropTypes.string,
    phone: PropTypes.string,
    Phone: PropTypes.string,
    sendInviteToPatientEmail: PropTypes.string,
    sendInviteToPatientPhone: PropTypes.string,
    sendingDataError: PropTypes.string,
  }),
};

AssessmentForm.defaultProps = {
  actions: undefined,
  backendErrors: undefined,
  hideSendingOptions: false,
};

export default AssessmentForm;
