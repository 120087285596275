import React from 'react';
import { fade, Chip, makeStyles } from '@material-ui/core';
import cx from 'classnames';
import PropTypes from 'prop-types';

export const BADGES_COLOR_TYPES = {
  green: 'green',
  green2: 'green2',
  blue: 'blue',
  grey: 'grey',
  lightGrey: 'lightGrey',
  red: 'red',
  orange: 'orange',
  lightBlue: 'lightBlue',
  purple: 'purple',
};

const useStyles = makeStyles((theme) => ({
  badge: {
    height: 24,
    borderRadius: theme.shape.borderRadius,
    fontSize: 13,
    letterSpacing: 1,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: `0 ${theme.spacing(2)}px`,
  },
  badgeLabel: {
    padding: 0,
  },
  noHorizontalPaddings: {
    padding: 0,
  },
  green: {
    color: theme.colors.green,
    backgroundColor: fade(theme.colors.green, 0.25),

    '&:hover': {
      backgroundColor: fade(theme.colors.green, 0.35),
    },
    '&:focus': {
      color: theme.colors.green,
      backgroundColor: fade(theme.colors.green, 0.25),
    },
  },
  green2: {
    color: theme.colors.darkGreen,
    backgroundColor: fade(theme.colors.darkGreen, 0.25),

    '&:hover': {
      backgroundColor: fade(theme.colors.darkGreen, 0.35),
    },
    '&:focus': {
      color: theme.colors.darkGreen,
      backgroundColor: fade(theme.colors.darkGreen, 0.25),
    },
  },
  lightBlue: {
    color: theme.colors.lightBlue,
    backgroundColor: fade(theme.colors.lightBlue, 0.25),

    '&:hover': {
      backgroundColor: fade(theme.colors.lightBlue, 0.35),
    },
    '&:focus': {
      color: theme.colors.lightBlue,
      backgroundColor: fade(theme.colors.lightBlue, 0.25),
    },
  },
  lightGrey: {
    color: theme.colors.grey,
    backgroundColor: fade(theme.colors.grey, 0),
    border: `0.5px solid ${theme.colors.grey}`,

    '&:hover': {
      backgroundColor: fade(theme.colors.grey, 0.1),
    },
    '&:focus': {
      color: theme.colors.grey,
      backgroundColor: fade(theme.colors.grey, 0),
      border: `0.5px solid ${theme.colors.grey}`,
    },
  },
  purple: {
    color: theme.colors.lightBlue2,
    backgroundColor: fade(theme.colors.lightBlue2, 0.25),
    border: `0.5px solid ${theme.colors.lightBlue2}`,

    '&:hover': {
      backgroundColor: fade(theme.colors.lightBlue2, 0.35),
    },
    '&:focus': {
      color: theme.colors.lightBlue2,
      backgroundColor: fade(theme.colors.lightBlue2, 0.25),
      border: `0.5px solid ${theme.colors.lightBlue2}`,
    },
  },
  blue: {
    color: theme.colors.blue,
    backgroundColor: fade(theme.colors.blue, 0.25),

    '&:hover': {
      backgroundColor: fade(theme.colors.blue, 0.35),
    },
    '&:focus': {
      color: theme.colors.blue,
      backgroundColor: fade(theme.colors.blue, 0.25),
    },  },
  grey: {
    color: theme.colors.grey,
    backgroundColor: fade(theme.colors.grey, 0.25),

    '&:hover': {
      backgroundColor: fade(theme.colors.grey, 0.35),
    },
    '&:focus': {
      color: theme.colors.grey,
      backgroundColor: fade(theme.colors.grey, 0.25),
    },  },
  red: {
    color: theme.palette.error.main,
    backgroundColor: fade(theme.palette.error.main, 0.25),

    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.35),
    },
    '&:focus': {
      color: theme.palette.error.main,
      backgroundColor: fade(theme.palette.error.main, 0.25),
    },
  },
  orange: {
    color: theme.colors.orange,
    backgroundColor: fade(theme.colors.orange, 0.25),

    '&:hover': {
      backgroundColor: fade(theme.colors.orange, 0.35),
    },
    '&:focus': {
      color: theme.colors.orange,
      backgroundColor: fade(theme.colors.orange, 0.25),
    },
  },
  badgeFixedWidth: {
    width: 120,
    padding: `0 ${theme.spacing(1)}px`,
  },
  diagonalBorderRadius: {
    borderRadius: '0 8px',
  },
  bottomLeftRadius: {
    borderRadius: '0 0 0 8px',
  },
}));

export const Badge = ({
  fixedWidth,
  label,
  colorType,
  diagonalBorderRadius,
  bottomLeftRadius,
  customStyle,
  ...restProps
}) => {
  const classes = useStyles();
  return (
    <Chip
      className={cx(
        classes.badge,
        customStyle,
        classes[colorType],
        fixedWidth && classes.badgeFixedWidth,
        diagonalBorderRadius && classes.diagonalBorderRadius,
        bottomLeftRadius && classes.bottomLeftRadius,
      )}
      classes={{ label: classes.badgeLabel }}
      label={label}
      {...restProps}
    />
  );
};

Badge.propTypes = {
  label: PropTypes.string,
  colorType: PropTypes.string,
  fixedWidth: PropTypes.bool,
  diagonalBorderRadius: PropTypes.bool,
  bottomLeftRadius: PropTypes.bool,
  customStyle: PropTypes.string,
};

Badge.defaultProps = {
  label: undefined,
  colorType: undefined,
  fixedWidth: false,
  diagonalBorderRadius: false,
  bottomLeftRadius: false,
  customStyle: undefined,
};
