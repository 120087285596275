import React, { useMemo } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import CurrencyFormatInput from '../../../../../../../../FormikFields/CurrencyFormatInput';
import cx from 'classnames';
import { alpha } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tableInput: {
    marginTop: 0,
  },
  tableItem: {
    flex: 7,
    minWidth: 260,
    height: 72,
    position: 'relative',
  },
  textField: {
    maxWidth: 600,
    height: 40,
  },
  zIndex: {
    zIndex: 0,
  },
  root: {
    height: '100%',
    minHeight: 40,
  },
  lightColorBorder: {
    borderColor: alpha('#818FA0', 0.25),
  },
}));

const styles = {
  activitiesErrorStyle: {
    position: 'absolute',
    bottom: -18,
    marginLeft: 20,
  },
};

const PriceComponent = (props) => {
  const classes = useStyles();
  const { rowData: { index }, backendErrors, updateFieldValue, validateField } = props;
  const inputProps = {
    disableUnderline: true,
    classes: {
      notchedOutline: classes.lightColorBorder,
    },
  };


  const errors = useMemo(
    () => (backendErrors &&
      backendErrors[index] &&
      backendErrors[index].price
    ),
    [backendErrors],
  );

  const onUpdateFieldValue = async (key, value) => {
    updateFieldValue(index, 'price', value);
    validateField(`ispConfiguration.services[${index}].price`);
  };

  return <CurrencyFormatInput
    required={true}
    type='number'
    hideErrorDuringEditing
    disableUnderline
    customInput={_CustomInput}
    name={`ispConfiguration.services[${index}].price`}
    backendError={errors}
    className={classes.tableInput}
    InputProps={inputProps}
    activitiesErrorStyle={styles.activitiesErrorStyle}
    onChange={onUpdateFieldValue}
  />;
};

export default PriceComponent;


const _CustomInput = (props) => {
  const classes = useStyles();
  return <TextField
    InputProps={{
      classes: {
        notchedOutline: classes.lightColorBorder,
        root: classes.root,
      },
    }}
    className={cx(
      classes.textField,
      classes.zIndex,
    )}
    autoComplete='off'
    fullWidth
    placeholder={'Price'}
    variant={'outlined'}
    classes={{ label: classes.label }}
    {...props}
  />;
};
