import { Box, Grid, Button, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';

import { useMutation } from 'react-fetching-library';
import PropTypes from 'prop-types';

import { ReactComponent as AssessmentIcon } from 'assets/icons/assessment-icon.svg';
import { ReactComponent as OrderIcon } from 'assets/icons/order-big-icon.svg';
import RoundedButton from 'components/Shared/RoundedButton';
import Modal from 'components/Shared/Modal';
import getDigitPhone from 'helpers/phone';
import showServerError from 'helpers/showError';
import { routes } from 'config/routes';
import PatientForm from 'components/Patients/PatientForm';
import { EMPTY_ADDRESS } from 'components/Shared/constants';
import { AppRouteContext } from 'contexts';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import useOrderMetadata from 'hooks/useOrderMetadata';
import useAssessmentMetadata from 'hooks/useAssessmentMetadata';
import { checkPermission } from 'helpers/checkPermissions';
import permissions from 'config/permissions';

import getFullName from 'helpers/user';
import { useDispatch, useSelector } from 'react-redux';
import { WARNING_DURATION } from '../../contexts/NotificationsProvider';
import PatientEditModal from './PatientEditModal';
import { getUtcDate } from '../../helpers/localize';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { PATIENT_LANGUAGE } from './constants';

const useStyles = makeStyles((theme) => ({
  goToListLink: {
    color: theme.colors.lightBlue,
    fontWeight: 400,
    fontSize: 15,
    textAlign: 'center',
    cursor: 'pointer',
  },
  modalBtn: {
    height: 128,
    width: 300,
    borderColor: theme.colors.lightBlue,
  },
  buttonText: {
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 2,
    width: 'max-content',
    color: theme.colors.blue2,
    textTransform: 'uppercase',
    paddingTop: theme.spacing(2),
  },
  modal: {
    width: '100%',
    maxWidth: 1000,
  },
  createButton: {
    textTransform: 'uppercase',
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(2),
    padding: theme.spacing(1, 2),
    letterSpacing: 2,
    fontWeight: 'bold',
    fontSize: 13,
  },
}));

const PatientCreateModal = ({ open, handleClose, accountUuid, reloadData }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const closeModal = () => {
    handleClose();
    setBackendErrors({});
  };
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const [openModal, toggleModal] = useState(false);
  const closeAfterCreationModal = () => toggleModal(false);
  const [patient, setPatient] = useState({}); // trigger useOrderMetadata
  const [action, setAction] = useState({});
  const dispatch = useDispatch();
  const { setAppRoute } = useContext(AppRouteContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const {
    user,
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const patientName = getFullName(patient);

  const successProvidersCallback = (patientInfo) => {
    dispatch(
      enqueueSnackbar({
        message: t('notifications:patientCreated', {
          name: getFullName(patientInfo),
        }),
        options: {
          variant: SNACKBAR_VARIANTS.success,
        },
      }),
    );
  };

  const errorProvidersCallback = () => {
    dispatch(
      enqueueSnackbar({
        message: `${t('notifications:patientCreated', {
          name: patientName,
        })} ${t('notifications:addProvider')}`,
        options: {
          variant: SNACKBAR_VARIANTS.warning,
          autoHideDuration: WARNING_DURATION,
        },
      }),
    );
    // no title needed
    setAppRoute({
      path: routes.accountPatientsList.path,
      params: {
        accountUuid,
      },
    });
  };

  const { loading: metadataLoading } = useOrderMetadata(
    action.createOrder,
    accountUuid,
    errorProvidersCallback,
    {
      actionTitle: 'Edit patient',
      reorderUuid: 'newOrder',
      actionCallback: () => {
        togglePatientEditModal(true);
      },
      callback: () => {},
    },
  );

  useAssessmentMetadata(
    action.createAssessment,
    accountUuid,
    errorProvidersCallback,
  );

  const PatientCreateAction = (values) => ({
    method: 'PUT',
    endpoint: `/accounts/${accountUuid}/patients`,
    body: values,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    PatientCreateAction,
  ]);

  const createPatient = async (values, { setSubmitting }) => {
    const formattedValues = {
      ...values,
      phone: { phone: getDigitPhone(values.phone) },
      dateOfBirth: getUtcDate(values.dateOfBirth),
    };
    setBackendErrors({});

    const { payload, error, status } = await mutate(formattedValues);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);
      setPatient(payload);
      successProvidersCallback(payload);
      closeModal();
      reloadData();
      toggleModal(true);
    }
  };

  return (
    <>
      <Modal
        open={openModal}
        disableEscapeKeyDown
        disableBackdropClick
        title={t('titles:patientAdded')}
        handleClose={closeAfterCreationModal}
      >
        <Box pb={2} pt={4} px={4}>
          <Grid container justify="space-evenly">
            <Grid item>
              <Button
                variant="outlined"
                className={classes.modalBtn}
                onClick={() =>
                  setAction({
                    createOrder: patient.uuid,
                  })
                }
              >
                <Box display="flex" alignItems="center" flexDirection="column">
                  <OrderIcon />
                  <Typography className={classes.buttonText}>
                    {t('btn:createOrder')}
                  </Typography>
                </Box>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                className={classes.modalBtn}
                onClick={() =>
                  setAction({
                    createAssessment: patient.uuid,
                  })
                }
              >
                <Box display="flex" alignItems="center" flexDirection="column">
                  <AssessmentIcon height="40" />
                  <Typography className={classes.buttonText}>
                    {t('btn:addAssessment')}
                  </Typography>
                </Box>
              </Button>
            </Grid>
          </Grid>
          <Box
            py={4}
            onClick={() => {
              closeAfterCreationModal();
              isDistributionSponsor // eslint-disable-line no-unused-expressions
                ? setAppRoute({
                    path: routes.accountPatientsList.path,
                    title: routes.accountPatientsList.additional.title,
                    params: {
                      accountUuid,
                    },
                  clearBreadcrumbs: true,
                })
                : setAppRoute({
                    path: routes.patientsList.path,
                    title: routes.patientsList.additional.title,
                    clearBreadcrumbs: true,
                });
            }}
          >
            <Typography className={classes.goToListLink}>
              {t('btn:goToListOfPatients')}
            </Typography>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={open}
        classes={{ paper: classes.modal }}
        title={t('titles:addNewPatient')}
        handleClose={closeModal}
      >
        <Formik
          initialValues={{
            mrnNumber: '',
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            dateOfBirth: null,
            genderAtBirth: '',
            hasDrugAllergies: null,
            smsOptIn: false,
            drugAllergies: '',
            address: {
              ...EMPTY_ADDRESS,
            },
            patientLanguage: PATIENT_LANGUAGE.UNKNOWN,
          }}
          validateOnBlur={false}
          onSubmit={createPatient}
        >
          {({ isValid, handleSubmit, dirty, isSubmitting, values }) => (
            <Box p={4}>
              <PatientForm
                values={values}
                backendErrors={backendErrors}
                actions={
                  <Box py={2} display="flex" justifyContent="space-between">
                    <RoundedButton
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={closeModal}
                      className={classes.createButton}
                    >
                      {t('btn:cancel')}
                    </RoundedButton>
                    <RoundedButton
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled={!isValid || isSubmitting || !dirty}
                      onClick={handleSubmit}
                      isLoading={loading || metadataLoading}
                      isSuccess={isSuccess}
                      isFailed={isFailed}
                      className={classes.createButton}
                    >
                      {t('btn:create')}
                    </RoundedButton>
                  </Box>
                }
              />
            </Box>
          )}
        </Formik>
      </Modal>
      {patient.uuid && (
        <PatientEditModal
          open={openPatientEditModal}
          patientUuid={patient.uuid}
          accountUuid={accountUuid}
          handleClose={() => togglePatientEditModal(false)}
        />
      )}
    </>
  );
};

PatientCreateModal.propTypes = {
  accountUuid: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
};

export default PatientCreateModal;
