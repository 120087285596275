import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import IdleDialog from 'components/Dialogs/IdleDialog';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeSnackbar,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { logout } from '../../redux_store/reducer/reducers/userReducer/api';

const IDLE_TIMEOUT = 1000 * 60 * 30; //ms / s / m / h
const LOGOUT_TIMEOUT = 1000 * 60; //ms / s / m / h

const Idle = () => {
  const { t } = useTranslation('notifications');
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isTabActive, setIsTabActive] = useState(true);
  const userLogoutNotification = useRef();
  const userActivityIdleRef = useRef();
  const idleRef = useRef();

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleIdle = () => {
    if (user) {
      const currentTime = new Date().getTime();
      const lastActiveTime = idleRef.current.getLastActiveTime();
      if (isTabActive) {
        if (currentTime - lastActiveTime > IDLE_TIMEOUT + LOGOUT_TIMEOUT) {
          window.location.href = '/login#auto_logout';
        } else {
          setDialogOpen(true);
        }
      } else {
        if (currentTime - lastActiveTime > IDLE_TIMEOUT) {
          window.location.href = '/login#auto_logout';
        }
      }
    }
  };

  const handleLogout = () => {
    if (user) {
      window.location.href = '/login#auto_logout';
    }
    setDialogOpen(false);
  };

  function handleVisibilityChange() {
    setIsTabActive(!document.hidden)
  }

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange, false);
  }, []);

  useEffect(() => {
    if (user && userLogoutNotification.current) {
      dispatch(closeSnackbar('logout'));
    }
  }, [user]);

  return (
    <>
      <IdleTimer
        ref={idleRef}
        element={document}
        onIdle={handleIdle}
        timeout={IDLE_TIMEOUT}
      />
      {isDialogOpen && (
        <IdleTimer
          ref={userActivityIdleRef}
          element={document}
          onIdle={handleLogout}
          timeout={LOGOUT_TIMEOUT}
        />
      )}
      <IdleDialog
        open={isDialogOpen}
        logout={() => {
          dispatch(logout());
          setDialogOpen(false);
        }}
        onClose={handleDialogClose}
        onLogout={handleLogout}
        logoutTimeout={LOGOUT_TIMEOUT / 1000}
      />
    </>
  );
};

export default Idle;
