import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import cx from 'classnames';

import DateFilter from 'components/Filters/DateFilter';
import SelectFilter from 'components/Filters/SelectFilter';
import TextFilter from 'components/Filters/TextFilter';
import FILTER_TYPES from 'components/Filters/constants';
import CurrencyTextFilter from './CurrencyTextFilter';
import SelectWithDynamicHintFilter from './SelectWithDynamicHintFilter/SelectWithDynamicHintFilter';

const useStyles = makeStyles((theme) => ({
  filterBox: {
    position: 'relative',
    width: `calc(100% + ${theme.spacing(3)}px)`,
    height: 32,
    minHeight: 32,
    left: theme.spacing(-1.5),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.spacing(2),
    padding: '0px 7px 0px 14px',
    alignItems: 'flex-end',
    display: 'flex',
  },
  isEditableModeStyle: {
    left: 0,
    width: '100%',
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  specialDateFilterPadding: {
    padding: '0px 12px 0px 14px',
    alignItems: 'center',
    cursor: 'default',
  },
}));

function FilterBox(props) {
  const {
    columnData: { filterType, isFilterable },
    isEditableMode,
    availableOptions,
  } = props;
  const classes = useStyles();

  let renderFilter;

  if (availableOptions) {
    renderFilter = <SelectWithDynamicHintFilter {...props} />;
  } else {
    renderFilter = (() => {
      switch (filterType) {
        case FILTER_TYPES.DATE: {
          return <DateFilter {...props} />;
        }
        case FILTER_TYPES.SELECT: {
          return <SelectFilter {...props} />;
        }
        case FILTER_TYPES.CHECKBOX: {
          return <SelectFilter {...props} hideMultiple={true} />;
        }
        case FILTER_TYPES.INPUT_CURRENCY: {
          return <CurrencyTextFilter {...props} />;
        }
        case FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT: {
          return <SelectWithDynamicHintFilter {...props} />;
        }
        default:
          return <TextFilter {...props} />;
      }
    })();
  }

  return (
    <div
      className={cx(classes.filterBox, {
        [classes.transparent]: !isFilterable,
        [classes.specialDateFilterPadding]: filterType === FILTER_TYPES.DATE,
        [classes.isEditableModeStyle]: isEditableMode,
      })}
    >
      {renderFilter}
    </div>
  );
}

FilterBox.propTypes = {
  columnData: PropTypes.shape({
    filterType: PropTypes.oneOf([
      FILTER_TYPES.DATE,
      FILTER_TYPES.INPUT,
      FILTER_TYPES.SELECT,
    ]),
    isFilterable: PropTypes.bool,
  }).isRequired,
};

export default FilterBox;
