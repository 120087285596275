const FILTER_TYPES = {
  DATE: 'date',
  INPUT: 'input',
  SELECT: 'select',
  CHECKBOX: 'checkbox',
  INPUT_CURRENCY: 'input_currency',
  SELECT_WITH_DYNAMIC_HINT: 'select_with_dynamic_hint',
};

export default FILTER_TYPES;
