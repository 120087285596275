import { useEffect, useContext } from 'react';
import { useQuery } from 'react-fetching-library';
import { useTranslation } from 'react-i18next';

import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { AppRouteContext } from 'contexts';
import { routes } from 'config/routes';

const useAssessmentMetadata = (
  patientUuid,
  accountUuid,
  errorCallback,
  successCallback,
) => {
  const { t } = useTranslation(['titles']);
  const { setAppRoute } = useContext(AppRouteContext);

  const { query, loading } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/patients/${patientUuid}/create-order-metadata`,
    },
    false,
  ]);

  useEffect(() => {
    if (patientUuid) {
      query().then(({ payload: metadata, error: metadataError }) => {
        if (!metadataError && metadata) {
          const { providers = [], patientInfo } = metadata;
          if (providers.length) {
            setAppRoute({
              path: routes.patientAssessmentListShowPopup.path,
              params: {
                patientUuid,
                accountUuid,
              },
              title: `${t('titles:patientDashboard')}: ${patientInfo.name}`,
            });
            if (successCallback) {
              successCallback();
            }
          } else if (errorCallback) {
            errorCallback();
          }
        }
      });
    }
  }, [patientUuid]);

  return {
    loading,
  };
};

export default useAssessmentMetadata;
