import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import { routes } from 'config/routes';
import permissions from 'config/permissions';
import { AppRouteContext } from 'contexts';
import { checkPermission } from 'helpers/checkPermissions';
import {
  orderStatusesActions,
  GET_ORDER_COLUMNS,
  FAKE_ORDER,
} from 'components/Orders/constants';
import ordersPlaceholderImage from 'assets/placeholders/orders.svg';
import { ReactComponent as EditIcon } from 'assets/icons/edit.svg';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/resend.svg';
import { useDispatch, useSelector } from 'react-redux';
import getFakeDataList from '../../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';
import { SNACKBAR_VARIANTS } from '../../../redux_store/reducer/reducers/notificationsReducer';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/file_download_black.svg';
import { useMutation } from 'react-fetching-library';
import showServerError from '../../../helpers/showError';

const OrdersTable = ({
                       modifiedOrders,
                       setReorder,
                       setOrderPatientUuid,
                       setOpenRemoveDialog,
                       setRemovingOrder,
                       setStatusChanging,
                       setOpenStatusDialog,
                       dataLoading,
                       totalCount,
                       restFetchProps,
                     }) => {
  const {
    en: {
      placeholders: tPlaceholders,
      titles: tTitles,
      notifications: tNotifications,
    },
  } = useSelector((state) => state.i18n);
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const { user } = useSelector((state) => state.user);
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbar(...args));
  const { setAppRoute } = useContext(AppRouteContext);
  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);

  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  const redirectToEditOrder = ({ uuid, accountUUID, patientUUID }) => {
    const hasPermission = checkPermission(
      accountUUID === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_PATCH,
    );
    if (hasPermission) {
      setAppRoute({
        path: routes.patientOrderEdit.path,
        params: {
          accountUuid: accountUUID,
          patientUuid: patientUUID,
          orderUuid: uuid,
        },
        title: tTitles.editOrder,
      });
    } else {
      enqueueSnackbar(tNotifications.notPermissionForEditingOrder, {
        variant: SNACKBAR_VARIANTS.warning,
      });
    }
  };

  const handleRowClick = (order) => {
    redirectToEditOrder(order);
  };

  const handleEditItemClick = (e, order) => {
    redirectToEditOrder(order);
  };

  const handleReorderItemClick = (e, order) => {
    console.log(order);

    setReorder(order);


    const hasPermission = checkPermission(
      order.accountUUID === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_CREATE,
    );
    console.log(hasPermission);

    if (hasPermission) {
      setOrderPatientUuid(order.patientUUID);
    } else {
      enqueueSnackbar(tNotifications.notPermissionForCreatingOrder, {
        variant: SNACKBAR_VARIANTS.warning,
      });
    }
  };

  const handleRemoveItemClick = (e, order) => {
    const hasPermission = checkPermission(
      paramAccountUuid === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_DELETE,
    );
    if (hasPermission) {
      setOpenRemoveDialog(true);
      setRemovingOrder(order);
    } else {
      enqueueSnackbar(tNotifications.notPermissionForDeletingOrder, {
        variant: SNACKBAR_VARIANTS.warning,
      });
    }
  };

  const handleStatusChange = (
    e,
    { uuid, accountUUID },
    selected,
    statusValue,
  ) => {
    const statusItem = orderStatusesActions.find(
      (orderStatus) => orderStatus.value === statusValue,
    );
    const newStatusChanging = {
      statusItem,
      orderUuid: uuid,
      accountUuid: accountUUID,
      selected,
    };
    if (selected.length > 1) {
      // show confirmation dialog
      setStatusChanging(newStatusChanging);
      setOpenStatusDialog(true);
    } else {
      // change status for single order
      setStatusChanging({
        ...newStatusChanging,
        preparedList: [{ accountUuid: accountUUID, target: [uuid] }],
      });
    }
  };

  const getImmunotherapyReportPDF = (orderUUID, accountUUID) => ({
    method: 'GET',
    endpoint: `/accounts/${accountUUID}/orders/${orderUUID}/immunotherapy-report`,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/pdf',
    },
  });

  const { mutate: getPDFFile } = useMutation(getImmunotherapyReportPDF);

  const downloadedPDFFile = async (e, order) => {
    const { payload, error, status } = await getPDFFile(order.uuid, order.accountUUID);
    if (error) {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 401: {
          showServerError(dispatch, tNotifications.notPermissionForEditingClaim);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload && payload.size > 0) {
      const url = window.URL.createObjectURL(new Blob([payload], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Immunotherapy report - ${order.patientLastName} ${order.patientFirstName} ${order.orderDate}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  const getOptions = () => {
    let statuses = orderStatusesActions;
    if (!isDistributionSponsor) {
      statuses = orderStatusesActions.filter(
        (orderStatus) => orderStatus.enabledForNonSponsors,
      );
    }
    return statuses.map((orderStatus) => ({
      ...orderStatus,
      onActionClick: handleStatusChange,
    }));
  };

  const isTableDataLoading = dataLoading || !modifiedOrders;
  const FAKE_ORDER_LIST = getFakeDataList(FAKE_ORDER);
  return (
    <VirtualizedTable
      tableKey={paramAccountUuid ? TABLE_KEYS.ordersDS : TABLE_KEYS.orders}
      hasColumnsSelect={true}
      fakeRows={FAKE_ORDER_LIST}
      rowCount={totalCount}
      rows={modifiedOrders}
      columns={GET_ORDER_COLUMNS(isPatientPage, paramAccountUuid)}
      onRowClick={handleRowClick}
      multiCheck
      FetchProps={restFetchProps}
      onDataLoading={isTableDataLoading}
      currentAccountPermissions={currentAccountPermissions}
      actionProps={{
        actions: [
          {
            key: '1',
            icon: EditIcon,
            onActionClick: handleEditItemClick,
            children: tTitles.editOrder,
          },
          {
            key: '2',
            icon: ResendIcon,
            onActionClick: handleReorderItemClick,
            children: tTitles.reorder,
          },
          {
            key: 'downloadImmunotherapyReport',
            icon: DownloadIcon,
            onActionClick: downloadedPDFFile,
            children: tTitles.downloadImmunotherapyReport,
            isDisabled: false,
          },
          {
            key: '3',
            icon: RemoveIcon,
            onActionClick: handleRemoveItemClick,
            children: tTitles.removeOrder,
            isRemove: true,
          },
        ],
        options: getOptions(),
        optionsEntity: 'orderStatus',
      }}
      placeholder={
        <TablePlaceholder
          image={ordersPlaceholderImage}
          itemsNotFoundText={getTransitionWithVariable(tPlaceholders.noItemsFound, {
            item: tTitles.orders,
          })}
          message={getTransitionWithVariable(tPlaceholders.reviseFiltersOrCreateYourFirstItem, {
            item: tTitles.order,
          })}
        />
      }
    />
  );
};

export default OrdersTable;
