export const EMPTY_ADDRESS = {
  name: '',
  streetAddress: '',
  suite: '',
  city: '',
  state: '',
  zipCode: '',
};

export const QUERY_DELAY = 1100;
export const NULL_QUERY_DELAY = 0;

export const DEBOUNCE_DELAY = 200;

export const dateFormats = [
  'MM/dd/yyyy',
  'M/dd/yyyy',
  'MM/d/yyyy',
  'M/d/yyyy',
  'MM-dd-yyyy',
  'M-dd-yyyy',
  'MM-d-yyyy',
  'M-d-yyyy',
];

export const ANSWER_COLORS_LIST = {
  usual: 'usual',
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'error',
};

export const SURVEYS_QUESTION_TYPES = {
  SingleSelect: 'select',
  MultiSelect: 'multiSelect',
  GridSelect: 'gridSelect',
  GridNumericalSelector: 'gridNumericalSelector',
  BasicNumericalSelector: 'basicNumericalSelector',
};

export const EMPTY_SURVEY_VALUE = 'NONE';
