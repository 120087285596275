import { REQUEST_STATUS } from './constants';

export const userInitialState = {
  isLogin: false,
  isLoginStatus: REQUEST_STATUS.idle,
  isLoginErrors: {},
  isMaintenanceMode: false,

  hipaaCheckParams: null,

  metadata: null,
  metadataStatus: REQUEST_STATUS.idle,

  user: null,
  status: REQUEST_STATUS.idle,
  error: null,

  blockedAccounts: [],
};
