import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  DialogContentText,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import RoundedButton from 'components/Shared/RoundedButton';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 2, 3),
  },
  progress: {
    color: theme.palette.common.white,
  },
}));

const IdleDialog = ({
  open,
  logout,
  onClose,
  onLogout,
  logoutTimeout,
  ...rest
}) => {
  const { t } = useTranslation(['btn', 'dialogs']);
  const classes = useStyles();
  const [counter, setCounter] = useState(logoutTimeout);
  const intervalRef = useRef();

  useEffect(() => {
    if (!counter) {
      onLogout();
    }
  }, [counter]);

  useEffect(() => {
    if (open) {
      intervalRef.current = setInterval(() => {
        setCounter((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
      setCounter(logoutTimeout);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="idle-title"
      fullWidth
      maxWidth="xs"
      disableBackdropClick
      disableEscapeKeyDown
      {...rest}
    >
      <DialogTitle id="idle-title">{t('dialogs:sessionTimeout')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('dialogs:inactivityWarning')}</DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.actions }}>
        <RoundedButton
          onClick={logout}
          variant="contained"
          color="secondary"
          size="small"
        >
          {t('btn:logOut')}
        </RoundedButton>
        <RoundedButton
          onClick={onClose}
          variant="contained"
          color="primary"
          size="small"
          endIcon={
            <CircularProgress
              variant="static"
              value={Math.round((counter / logoutTimeout) * 100)}
              size={18}
              className={classes.progress}
            />
          }
        >
          {t('btn:stayLoggedIn')}
        </RoundedButton>
      </DialogActions>
    </Dialog>
  );
};

IdleDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  logoutTimeout: PropTypes.number.isRequired,
};

export default IdleDialog;
