import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginLeft: theme.spacing(2)
  }
}));

const AllowedAccountsSection = ({
                                  values,
                                  backendErrors,
                                  toggleAllowedAccountsModal,
}) => {
  const { t } = useTranslation([
    'forms',
  ]);
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.divider} />
      <Grid container direction='column' alignItems='flex-start'>
        <Typography className={classes.title}>
          {t('forms:accountAccess')}:
        </Typography>
        <Grid container direction='row' alignItems='center' justifyContent={'flex-start'}>
          <Typography className={cx(classes.error)}>
            {t('forms:accountAccessNumber', { number: values?.allowedAccounts?.length ?? 0 })}
          </Typography>
          <Button
            color='primary'
            variant={'contained'}
            className={cx(classes.button)}
            onClick={() => {
              toggleAllowedAccountsModal(true)
            }}
          >
            {t('forms:modifyAccountAccessList')}
          </Button>
        </Grid>
        {!!backendErrors['accountAccess'] && (
          <Typography className={cx(classes.error)}>
            {backendErrors['accountAccess']}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default AllowedAccountsSection;
