/* eslint-disable */
import React, { useState } from 'react';
import {
  Box,
  Collapse,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import smoothscroll from 'smoothscroll-polyfill';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
  linkTitle: {
    fontSize: 15,
    fontWeight: 400,
    marginBottom: 14,
    paddingLeft: theme.spacing(2),
    cursor: 'pointer',
    color: theme.colors.blue2,
  },
  navListContainer: {
    position: 'relative',
  },
  pointer: {
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 2,
    marginRight: 2,
  },
  icon: {
    color: theme.colors.lightBlue,
    fontSize: 32,
  },
  navListBox: {
    position: 'sticky',
    top: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  activeLinkTitle: {
    color: theme.colors.lightBlue,
  },
  open: {
    transform: 'rotate( 180deg )',
    transition: 'transform 150ms ease',
  },
  close: {
    transform: 'rotate( 360deg )',
    transition: 'transform 150ms ease',
  },
}));

const ReportNavigation = ({
                            currentBlockId,
                            setCurrentBlockId,
                            blockTitles = [],
                            isOpenDefaultTabSelected,
                            setOpenBlock,
                            setRefs,
                            refs,
                            topRef,
                          }) => {
  const classes = useStyles();
  const [counterOpenTabs, setCounterOpenTabs] = useState(0);
  const { t } = useTranslation(['titles']);
  const [isNavOpen, setIsNavOpen] = useState(true);

  const handleClick = (title) => {
    setCurrentBlockId(title , true);

    if (!refs[title].open) {
      setOpenBlock(title);
      const newRefs = { ...refs };
      newRefs[title].open = true;
      setRefs(newRefs);
      isOpenDefaultTabSelected(newRefs);
      setCounterOpenTabs(counterOpenTabs + 1);
    }

    // Wait for the section expanding animation to play
    setTimeout( ()  => {
      if(title === blockTitles[0]) {
         topRef.current.scrollIntoView({
          block: 'start',
          inline: 'start',
          behavior: 'smooth',
        })
        return;
      }
       refs[title].ref.current.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'smooth',
      })
    }, refs[title].open ? 0 : 460);
  };

  return (
    <Grid item xs={3} className={classes.navListContainer}>
      <Box className={classes.navListBox}>
        <Box
          height={20}
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.pointer}
          onClick={() => setIsNavOpen(!isNavOpen)}
        >
          <Typography className={classes.title}>{t('titles:reportNavigation')}</Typography>
          <KeyboardArrowDown
            className={cx(classes.icon, {
              [classes.open]: isNavOpen,
              [classes.close]: !isNavOpen,
            })}
          />
        </Box>
        <Collapse in={isNavOpen}>
          {blockTitles.map((title, index) => (
            <Typography
              key={index}
              onClick={() => handleClick(title)}
              className={cx(
                classes.linkTitle,
                title === currentBlockId && classes.activeLinkTitle,
              )}
            >
              {title}
            </Typography>
          ))}
        </Collapse>
      </Box>
    </Grid>
  );
};

export default ReportNavigation;
