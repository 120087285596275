import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Chip, makeStyles } from '@material-ui/core';
import { SortDirection } from 'react-virtualized';
import { get, isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import useFetchList from 'hooks/useFetchList';
import showServerError from 'helpers/showError';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import useOrderMetadata from 'hooks/useOrderMetadata';
import ordersPlaceholderImage from 'assets/placeholders/orders.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ActivePatientContext } from '../../../contexts/ActivePatientProvider';
import { NULL_QUERY_DELAY } from '../../Shared/constants';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import PatientActions from '../../Patients/PatientActions';
import PatientModals from '../../Patients/PatientModals';
import PatientAdditionalNode from '../../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../../Patients/PatientInfoBlock';
import {
  REPORTABLES_FOR_PATIENT_FIELDS,
  FIELDS,
  GET_REPORTABLES_COLUMNS,
  FAKE_REPORTABLE,
} from '../constants';
import getFakeDataList, { FAKE_TITLE_DATA } from '../../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../../redux_store/reducer/reducers/tableColumnsReducer';
import { CUSTOM_TAGS_OPTIONS } from '../../Shared/CustomTags';
import { getTransitionWithVariable } from '../../../redux_store/reducer/reducers/i18nReducer';
import GenerateClaimReportModal from './widgets/GenerateClaimReportModal';
import {
  CLAIM_REPORTS_STATUSES_OPTIONS,
  CLAIM_REPORTS_STATUSES,
} from '../../ClaimReport/constants';
import RoundedButton from '../../Shared/RoundedButton';
import TableCellMore from '../../Table/TableCellMore';
import TableChip from '../../Table/TableChip';
import TableChips from '../../Table/TableChip';

const useStyles = makeStyles((theme) => ({
  titleItem: {
    position: 'relative',
    marginLeft: 4,
    marginTop: 12,
    fontWeight: 500,
    height: 21,
    fontSize: 11,
    color: theme.palette.common.white,
    backgroundColor: theme.colors.lightBlue,

    '@media print': {
      display: 'none',
    },
  },
  titleCountLabel: {
    padding: theme.spacing(0, 1),

    '@media print': {
      display: 'none',
    },
  },
  paper: {
    overflow: 'hidden',
  },
}));

/*
  could be rendered in 2 cases:
  - all account reportables
  - reportables for a special user (patientUuid in URL params)
*/
const ReportablesList = () => {
  const {
    en: {
      titles,
      tables,
      errors,
      placeholders,
      btn,
    },
  } = useSelector((state) => state.i18n);
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbar(...args));
  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const [filterStyle, setFilterStyle] = useState(false);
  const [isOpenStatusDialog, setOpenStatusDialog] = useState(false);
  const [openAssessmentModal, toggleAssessmentModal] = useState(
    window.location.pathname.includes('create-assessment'),
  );
  const [openResourceModal, toggleResourceModal] = useState(false);
  const [resetSelected, updateResetSelected] = useState(false);
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const [selectedItems, updateSelectedItems] = useState([]);
  const [selectedItemsAccount, updateSelectedItemsAccount] = useState();
  const [modifiedReportables, setModifiedReportables] = useState();
  const [orderPatientUuid, setOrderPatientUuid] = useState(null); // trigger useOrderMetadata
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);

  useEffect(() => {
    if (modifiedReportables) {
      let selectedItemsAccountUUid;
      if (selectedItems.length && modifiedReportables) {
        const firstSelectedItem = modifiedReportables.find((item) => item.uuid === selectedItems[0]);
        selectedItemsAccountUUid = firstSelectedItem?.accountUUID;
        updateSelectedItemsAccount({
          businessName: firstSelectedItem?.accountBusinessName,
          uuid: firstSelectedItem?.accountUUID,
        });
      }
      setModifiedReportables(
        (modifiedReportables || []).map((reportable) => {
          if (!selectedItems.length) {
            return {
              ...reportable,
              disabledCheckbox: reportable.reportStatus !== 'Reportable',
              disabledRowMenu: reportable.reportStatus !== 'Reportable',
            };
          } else {
            // user can select only rows with same accountUUID,
            const hesTheSameAccountUUID = selectedItemsAccountUUid === reportable.accountUUID;
            return {
              ...reportable,
              disabledCheckbox: reportable.reportStatus !== 'Reportable' || !hesTheSameAccountUUID,
              disabledRowMenu: reportable.reportStatus !== 'Reportable' || !hesTheSameAccountUUID,
            };
          }
        }),
      );
    }
  }, [selectedItems]);

  const { loading: metadataLoading } = useOrderMetadata(
    orderPatientUuid,
    paramAccountUuid,
    () => {
      showServerError(dispatch, errors.noProvidersOnThisAccount);
      setOrderPatientUuid(null);
    },
    {
      actionTitle: 'Edit patient',
      reorderUuid: 'newOrder',
      actionCallback: () => {
        togglePatientEditModal(true);
      },
      callback: () => {
        setOrderPatientUuid(null);
      },
    },
  );

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }/reportables`;
  const {
    items,
    totalAccountsCount,
    totalCount,
    completionRate,
    aggregations,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: (isPatientPage ? REPORTABLES_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {
      sortBy: 'entityOrderTimestamp',
      sortDirection: SortDirection.DESC,
    },
    baseFilter: {
      reportStatus: CLAIM_REPORTS_STATUSES.reportable,
    },
  });

  const { filter } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);
  const [headerCheckboxDisabled, setHeaderCheckboxDisabled] = useState(false);

  useEffect(() => {
    if (items) {
      let headerCheckboxDisabled = false;
      let prevAccountUuid;

      setModifiedReportables(
        items.map((reportable) => {
          if (!prevAccountUuid) {
            prevAccountUuid = reportable.accountUUID;
          } else if (!headerCheckboxDisabled && reportable.accountUUID !== prevAccountUuid) {
            headerCheckboxDisabled = true;
          }

          return {
            ...reportable,
            patientDateOfBirth: getLocalizedDate(
              reportable.patientDateOfBirth,
              'UTC',
            ),
            reportableGeneratedTimestamp: getLocalizedDate(
              reportable.reportableGeneratedTimestamp,
            ),
            accountCustomIdentifier: reportable.accountCustomIdentifier
              ? reportable.accountCustomIdentifier
              : 'N/A',
            entityOrderTimestamp: getLocalizedDate(reportable.entityOrderTimestamp),
            appointmentTimestamp: getLocalizedDate(reportable.appointmentTimestamp),
            paymentDate: getLocalizedDate(reportable.paymentDate),
            dos: getLocalizedDate(reportable.dos),
            entityCompletedTimestamp: getLocalizedDate(
              reportable.entityCompletedTimestamp,
            ),
            allowed: reportable.allowed.toString(),
            paid: reportable.paid.toString(),
            pr: reportable.pr.toString(),
            price: reportable.price.toString(),
            moreTitle: isPatientPage
              ? `${reportable.surveyName}`
              : reportable.patientFirstName && reportable.patientLastName
                ? `${reportable.patientFirstName} ${reportable.patientLastName}`
                : 'Actions',
            patientMrnNumber: !reportable.patientMrnNumber ? '-' : reportable.patientMrnNumber,
            reportStatus: reportable.reportStatus ? getLocalizedLabel(
              CLAIM_REPORTS_STATUSES_OPTIONS,
              reportable.reportStatus,
            ) : '-',
            disabledCheckbox: reportable.reportStatus !== CLAIM_REPORTS_STATUSES.reportable,
            disabledRowMenu: reportable.reportStatus !== CLAIM_REPORTS_STATUSES.reportable,
            accountCustomTags: reportable.accountCustomTags
              ? reportable.accountCustomTags.map(item => getLocalizedLabel(
                CUSTOM_TAGS_OPTIONS,
                item,
              ))
              : [],
          };
        }),
      );

      setHeaderCheckboxDisabled(headerCheckboxDisabled);
    }
  }, [items]);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const reloadReportableList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
    FAKE_TITLE_DATA
    : titles.reportables;

  const isTableDataLoading = dataLoading || !modifiedReportables;
  const FAKE_REPORTABLE_LIST = getFakeDataList(FAKE_REPORTABLE);
  return (
    <>
      {isPatientPage && (
        <PatientModals
          openPatientEditModal={openPatientEditModal}
          handleClosePatientEditModal={() => {
            togglePatientEditModal(false);
          }}
          openAssessmentModal={openAssessmentModal}
          handleCloseAssessmentModal={() => toggleAssessmentModal(false)}
          openResourceModal={openResourceModal}
          handleCloseResourceModal={() => toggleResourceModal(false)}
          reloadData={() => null}
        />
      )}
        <ContentWrapper
          titleText={titleText}
          className={classes.paper}
          hasTopPaddingForSmallScreen={isPatientPage}
          underTitleText={
            isPatientPage ? (
              <PatientInfoBlock
                isForInitLoading={
                  (!items && patientUuid !== paramsPatientUuid) || !patientInfo
                }
              />
            ) : (
              ''
            )
          }
          count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
          countFiltered={filterStyle}
          actions={
            <>
              {isPatientPage ? (
                <PatientActions
                  isLoading={metadataLoading}
                  toggleAssessmentModal={toggleAssessmentModal}
                  toggleResourceModal={toggleResourceModal}
                  togglePatientEditModal={togglePatientEditModal}
                  setOrderPatientUuid={setOrderPatientUuid}
                />
              ) : (
                <>
                  <RefreshTableButton
                    reloadData={reloadReportableList}
                    isLoading={dataLoading}
                  />
                  <RoundedButton
                    key={'generateReport'}
                    variant='outlined'
                    color='primary'
                    size='small'
                    onClick={() => setOpenStatusDialog(true)}
                    disableTextUppercase={true}
                    disabled={!selectedItems || !selectedItems.length}
                    style={{
                      height: 49,
                      marginLeft: 16,
                    }}
                  >
                    {btn.generateReport}
                  </RoundedButton>
                </>
              )}
            </>
          }
          additionalNode={
            <div style={{ display: 'flex', flexdirection: 'column' }}>
              {
                paramsPatientUuid
                  ? <PatientAdditionalNode
                    activeItemNumber={5}
                    modifiedData={modifiedReportables}
                    totalCount={totalCount}
                    reloadData={reloadReportableList}
                    dataLoading={dataLoading}
                    historyTitle={tables.reportableHistory}
                  />
                  : <TableChips aggregations={aggregations} />
              }
            </div>
          }
        >
          <VirtualizedTable
            tableKey={paramAccountUuid ? TABLE_KEYS.reportableDS : TABLE_KEYS.reportable}
            hasColumnsSelect={true}
            fakeRows={FAKE_REPORTABLE_LIST}
            rowCount={totalCount}
            rows={modifiedReportables}
            columns={GET_REPORTABLES_COLUMNS(
              isPatientPage,
              paramAccountUuid,
              totalAccountsCount,
            )}
            headerCheckboxDisabled={headerCheckboxDisabled}
            updateSelectedItems={updateSelectedItems}
            multiCheck
            resetSelected={resetSelected}
            updateResetSelected={updateResetSelected}
            FetchProps={{ ...restFetchProps }}
            onDataLoading={isTableDataLoading}
            currentAccountPermissions={currentAccountPermissions}
            placeholder={
              <TablePlaceholder
                image={ordersPlaceholderImage}
                itemsNotFoundText={getTransitionWithVariable(placeholders.noItemsFound, {
                  item: titles.reportables,
                })}
                message={getTransitionWithVariable(placeholders.reviseFiltersOrCreateYourFirstItem, {
                  item: titles.reportables,
                })}
              />
            }
          />
          <GenerateClaimReportModal
            selectedItems={selectedItems}
            accountUuid={selectedItemsAccount?.uuid}
            resetSelected={updateResetSelected}
            accountBusinessName={selectedItemsAccount?.businessName}
            reloadData={reloadReportableList}
            isOpenStatusDialog={isOpenStatusDialog}
            setOpenStatusDialog={setOpenStatusDialog}
          />
        </ContentWrapper>
    </>
  );
};

export default ReportablesList;
