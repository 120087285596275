import React from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Modal from '../../Shared/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100%',
    maxWidth: 1000,
    padding: '0 40px 40px 40px',
  },
  modalContainer: {
    width: '100%',
    maxWidth: 1000,
  },
  modalSecondTitle: {
    paddingBottom: theme.spacing(2),
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down(1000)]: {
      padding: theme.spacing(0),
    },
  },
  bold: {
    fontWeight: 600,
  },
  blackText: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  text: {
    fontSize: '0.875rem',
    lineHeight: 1.43,
    marginTop: 20,
  },
  list: {
    margin: 0,
  },
  decimalList: {
    listStyleType: 'decimal',
  },
}));

const QScaleReport = ({ showHelpPopup, setShowHelpPopup }) => {
  const classes = useStyles();
  const { t } = useTranslation(['QScaleReport']);

  return (
    <Modal
      open={showHelpPopup}
      handleClose={() => {
        setShowHelpPopup(false);
      }}
      classes={{ paper: classes.modal, container: classes.modalContainer }}
      title={t('QScaleReport:title')}
      secondTitle={t('QScaleReport:subtitle')}
      secondTitleStyle={classes.modalSecondTitle}
    >
      <div className={cx(classes.blackText, classes.bold)}>
        {t('QScaleReport:firstPart')}
      </div>
      <div className={cx(classes.blackText, classes.bold)}>
        {t('QScaleReport:secondPart')}
      </div>
      <div className={cx(classes.blackText, classes.bold)}>
        {t('QScaleReport:thirdPart')}
      </div>
      <div className={cx(classes.blackText, classes.bold)}>
        {t('QScaleReport:fourthPart')}
      </div>
      <div className={cx(classes.text, classes.bold)}>
        {t('QScaleReport:referencesTitle')}
      </div>
      <ul className={cx(classes.decimalList, classes.list)}>
        <li>{t('QScaleReport:referencesFirst')}</li>
        <li>{t('QScaleReport:referencesSecond')}</li>
      </ul>
    </Modal>
  );
};

QScaleReport.propTypes = {
  showHelpPopup: PropTypes.bool.isRequired,
  setShowHelpPopup: PropTypes.func.isRequired,
};

export default QScaleReport;
