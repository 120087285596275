import React, { useContext } from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';

import NavWrapper from 'components/Navigation/NavWrapper';
import NavFooter from 'components/Navigation/NavFooter';
import { ReactComponent as LogoIcon } from 'assets/icons/qlab-logo.svg';
import cx from 'classnames';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { useDispatch, useSelector } from 'react-redux';
import { toggleIsSideMenuPinned } from '../../redux_store/reducer/reducers/appSettingsReducer';
import { UserContext } from '../../contexts';
import BlurScreen from '../Shared/blur_screen';

const useStyles = makeStyles((theme) => ({
  navBar: {
    flexShrink: 0,
    width: 70,
    minWidth: 70,
    transition: '0.3s',
    flexWrap: 'nowrap',
    background: 'linear-gradient(180deg, #2E48D3 0%, #00A8FF 100%)',
    height: '100vh',
    position: 'relative',

    '& #navFooter': {
      transition: '0.3s',
      opacity: 0,
    },
    '&:hover': {
      '& #pushPinContainer': {
        transition: '0.3s',
        opacity: 1,
      },
    },
  },
  pinnedNavBar: {
    width: 240,
    flexWrap: 'nowrap',
    background: 'linear-gradient(180deg, #2E48D3 0%, #00A8FF 100%)',
    height: '100vh',
    transition: '0.3s',

    '& #navFooter': {
      transition: '0.3s',
      opacity: 1,
    },
  },
  logoContainer: {
    height: 84,
    minHeight: 84,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    height: 24,
    marginLeft: theme.spacing(1),
    fill: theme.palette.common.white,
    minWidth: 135,
  },
  pushPinContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 15,
    height: 60,
    opacity: 0.2,
    transition: '0.3s',
    position: 'absolute',
    top: 132,
    right: -15,
    backgroundColor: '#2c58db',
    borderRadius: 0,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    padding: 0,
    borderLeft: `1px solid black`,
    zIndex: 1,

    '&:hover': {
      opacity: 1,
      transition: '0.3s',
      backgroundColor: '#2c58db',
    },
  },
  icon: {
    color: '#FFFFFF',
  },
  iconRotate: {
    transform: 'rotate(180deg)',
  },
  blurDashboard: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  hideBlur: {
    height: 0,
  },
}));

const NavBar = () => {
  const classes = useStyles();
  const { isSideMenuPinned } = useSelector((state) => state.appSettings);
  const dispatch = useDispatch();
  const { changeAccountLoading } = useContext(UserContext);
  return (
    <Grid
      container
      direction='column'
      id={'navBar'}
      className={cx(classes.navBar, {
        [classes.pinnedNavBar]: isSideMenuPinned,
      })}
    >
      <IconButton
        aria-label='Search'
        onClick={() => dispatch(toggleIsSideMenuPinned())}
        className={classes.pushPinContainer}
        id='pushPinContainer'
      >
        <ChevronRight
          className={cx(classes.icon, {
            [classes.iconRotate]: isSideMenuPinned,
          })}
        />
      </IconButton>
      <Grid className={classes.logoContainer}>
        <div style={{
          position: 'absolute',
          left: 12,
          width: '100%',
          overflow: 'hidden',
          opacity: isSideMenuPinned ? 1 : 0,
          transition: 'opacity .3s',
          height: 24,
        }}>
          <LogoIcon className={classes.logo} />
        </div>
        <div style={{
          position: 'absolute',
          left: 12,
          width: 48,
          overflow: 'hidden',
          opacity: isSideMenuPinned ? 0 : 1,
          transition: 'opacity .3s',
          height: 24,
        }}>
          <LogoIcon className={classes.logo} />
        </div>
      </Grid>
      <BlurScreen
        isBlurShown={changeAccountLoading}
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <NavWrapper />
        <NavFooter />
      </BlurScreen>
    </Grid>
  );
};

export default NavBar;
