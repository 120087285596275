import React, { useRef, useState } from 'react';
import { Grid, makeStyles, Typography, Link, Box, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import TermsDialog from '../Dialogs/TermsDialog';
import PrivacyDialog from '../Dialogs/PrivacyDialog';
import { useSelector } from 'react-redux';
import HippaBaaDialog from '../Dialogs/HippaBaaDialog';
import smoothscroll from 'smoothscroll-polyfill';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const BUILD = process.env.REACT_APP_BUILD_NUMBER;

const DIALOG_TYPE = {
  terms: 'terms',
  hippa_baa: 'HIPPA_BAA',
  privacy: 'privacy',
};

const defaultIsDialogOpenValue = {
  type: '',
  open: false,
};

const useStyles = makeStyles((theme) => ({
  navFooter: {
    marginTop: 'auto',
    padding: theme.spacing(2),
    minWidth: 240,
  },
  text: {
    fontSize: 14,
    color: theme.palette.common.white,
  },
  link: {
    cursor: 'pointer',
  },
  divider: {
    width: 1,
    height: 16,
    backgroundColor: theme.colors.lightBlue,
    margin: theme.spacing(0, 1),
  },
  animatedItem: {
    animation: `$myEffect 300ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedItemExiting: {
    display: 'none',
    animation: `$myEffectExit 300ms ${theme.transitions.easing.easeInOut}`,
    opacity: 0,
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes myEffectExit': {
    '0%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
    },
  },
}));

const NavFooter = () => {
  const classes = useStyles();
  const { t } = useTranslation('nav');
  const divider = <div className={classes.divider} />;
  const [isDialogOpen, setDialogOpen] = useState(defaultIsDialogOpenValue);
  const hippaBaaRef = useRef(null);
  const termsRef = useRef(null);
  const privacyRef = useRef(null);
  const {
    metadata: {
      currentAccount: { businessName },
    },
  } = useSelector((state) => state.user);

  let env = '';
  switch (process.env.REACT_APP_ENV) {
    case 'development': {
      env = t('dev');
      break;
    }
    case 'testing': {
      env = t('testing');
      break;
    }
    default: {
      break;
    }
  }

  const handleTermsClick = () => {
    setDialogOpen({
      type: DIALOG_TYPE.terms,
      open: true,
    });
    scrollToTopOfDialog();
  };

  const handlePrivacyClick = () => {
    setDialogOpen({
      type: DIALOG_TYPE.privacy,
      open: true,
    });
    scrollToTopOfDialog();
  };

  const handleHippaBaaClick = () => {
    setDialogOpen({
      type: DIALOG_TYPE.hippa_baa,
      open: true,
    });
    scrollToTopOfDialog();
  };

  const scrollToTopOfDialog = () => {
      setTimeout(() => {
        if (hippaBaaRef.current) {
          hippaBaaRef.current.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: 'smooth',
          });
        }
        if (termsRef.current) {
          termsRef.current.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: 'smooth',
          });
        }
        if (privacyRef.current) {
          privacyRef.current.scrollIntoView({
            block: 'start',
            inline: 'start',
            behavior: 'smooth',
          });
        }
      }, 30);
  };

  const closeModal = () => {
    setDialogOpen(defaultIsDialogOpenValue);
  };

  return (
    <div className={classes.navFooter} id='navFooter'>
      <Typography className={classes.text}>
        {`© ${new Date().getFullYear()} ${t('qhsLabs')}`}
      </Typography>
      <Grid container alignItems='center'>
        <Typography className={classes.text}>
          {`${t('build')}: ${BUILD}`}
        </Typography>
        {env && (
          <>
            {divider}
            <Typography className={classes.text}>
              {`${t('env')}: ${env}`}
            </Typography>
          </>
        )}
      </Grid>
      <Box mt={3}>
        <Dialog
          open={isDialogOpen.open}
          onClose={closeModal}
          aria-labelledby='invite-title'
          fullWidth
          maxWidth='lg'
        >
          <div
            style={{ overflow: 'auto' }}
          >
            <div
              ref={hippaBaaRef}

              className={cx(classes.animatedItem, {
                [classes.animatedItemExiting]: isDialogOpen.type !== DIALOG_TYPE.hippa_baa,
              })}
            >
              <HippaBaaDialog
                onClose={closeModal}
                accountName={businessName}
              />
            </div>
            <div
              ref={termsRef}
              className={cx(classes.animatedItem, {
                [classes.animatedItemExiting]: isDialogOpen.type !== DIALOG_TYPE.terms,
              })}
            >
              <TermsDialog
                onClose={closeModal}
                accountName={businessName}
                onHippaOpen={handleHippaBaaClick}
                onPrivacyOpen={handlePrivacyClick}
              />
            </div>

            <div
              className={cx(classes.animatedItem, {
                [classes.animatedItemExiting]: isDialogOpen.type !== DIALOG_TYPE.privacy,
              })}
              ref={privacyRef}
            >
              <PrivacyDialog
                onClose={closeModal}
                accountName={businessName}
              />
            </div>
          </div>


        </Dialog>
        <Grid container alignItems='center'>
          <Link
            className={cx(classes.text, classes.link)}
            onClick={handleTermsClick}
          >
            {t('terms')}
          </Link>
          {divider}
          <Link
            className={cx(classes.text, classes.link)}
            onClick={handlePrivacyClick}
          >
            {t('privacy')}
          </Link>
          {divider}
          <Link
            className={cx(classes.text, classes.link)}
            onClick={handleHippaBaaClick}
          >
            {t('hippa_baa')}
          </Link>
        </Grid>
      </Box>
    </div>
  );
};

export default NavFooter;
