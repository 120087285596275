import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '../../client';
import { REQUEST_STATUS } from './userReducer/constants';

const checkSideMenu = () => {
  return localStorage.getItem('IsSideMenuPinned');
};

const setIsSideMenuPinned = (val) => {
  localStorage.setItem('IsSideMenuPinned', val);
};

export const fetchDisabledFeatures = createAsyncThunk(
  'disabledfeatures/fetchDisabledfeatures',
  async () => {
    const res = await client.get(`/system/disabledfeatures`);
    return res;
  },
);
export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    isSideMenuPinned: true,
    disabledFeatures: [],
    status: REQUEST_STATUS.idle,
    error: null,
  },
  reducers: {
    getAndSetIsSideMenuPinned: (state) => {
      if (checkSideMenu() === 'false') {
        state.isSideMenuPinned = false;
      }
    },
    toggleIsSideMenuPinned: (state) => {
      const newIsSideMenuPinned = !state.isSideMenuPinned;

      setIsSideMenuPinned(newIsSideMenuPinned);
      state.isSideMenuPinned = newIsSideMenuPinned;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDisabledFeatures.pending, (state) => {
        state.status = REQUEST_STATUS.loading;
      })
      .addCase(fetchDisabledFeatures.fulfilled, (state, action) => {
        if (action.payload.ok) {
          state.status = REQUEST_STATUS.succeeded;
          state.disabledFeatures = action.payload.data.data;
        } else {
          state.status = REQUEST_STATUS.failed;
          state.error = action.payload.error.message;
        }
      });
  },
});

export const {
  getAndSetIsSideMenuPinned,
  toggleIsSideMenuPinned,
} = appSettingsSlice.actions;

export default appSettingsSlice.reducer;
