import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import { compare } from 'fast-json-patch';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import UserForm from 'components/Users/UserForm';
import Loader from 'components/Shared/Loader';
import { routes } from 'config/routes';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { AppRouteContext } from 'contexts';
import BackButton from 'components/Shared/BackButton';
import getFullName from 'helpers/user';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';

const UserEdit = () => {
  const { accountUuid, userUuid } = useParams();
  const { t } = useTranslation(['errors', 'notifications', 'btn']);
  const [backendErrors, setBackendErrors] = useState({});
  const dispatch = useDispatch();
  const [user, setUser] = useState();
  const [originUser, setOriginUser] = useState();
  const { setAppRoute } = useContext(AppRouteContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/users/${userUuid}`,
    },
    false,
  ]);

  useEffect(() => {
    query().then(({ error, payload }) => {
      if (!error) {
        setOriginUser(payload);
        const parsedUser = {
          ...payload,
          managesUsers: payload.userPermissions?.managesUsers,
          receiveClaimsEmails: payload.userPermissions?.receiveClaimsEmails,
          receiveSurveysEmails: payload.userPermissions?.receiveSurveysEmails,
          imminentRiskEmails: payload.userPermissions?.imminentRiskEmails,
          administerClaims: payload.userPermissions?.administerClaims,
          administerClaimReports: payload.userPermissions?.administerClaimReports,
        };
        delete parsedUser.userPermissions;
        setUser(parsedUser);
      } else {
        setAppRoute({
          path: routes.notFoundProtected.path,
          clearBreadcrumbs: true,
          removeQueryString: true,
        });
      }
    });
  }, [query]);

  const userEditAction = (patches) => ({
    method: 'PATCH',
    endpoint: `/accounts/${accountUuid}/users/${userUuid}`,
    body: patches,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [userEditAction]);

  const editUser = async (values, { setSubmitting }) => {
    const parsedValues = {
      ...values,
      userPermissions: {
        managesUsers: values.managesUsers,
        receiveClaimsEmails: values.receiveClaimsEmails,
        receiveSurveysEmails: values.receiveSurveysEmails,
        imminentRiskEmails: values.imminentRiskEmails,
        administerClaims: values.administerClaims,
        administerClaimReports: values.administerClaimReports,
      },
    };
    delete parsedValues.managesUsers;
    delete parsedValues.receiveClaimsEmails;
    delete parsedValues.receiveSurveysEmails;
    delete parsedValues.imminentRiskEmails;
    delete parsedValues.administerClaims;
    delete parsedValues.administerClaimReports;

    const patches = compare(originUser, parsedValues);

    if (patches.length) {
      setBackendErrors({});
      const { payload, error, status } = await mutate(patches);
      if (error) {
        setIsFailed(true);
        setTimeout(() => {
          setIsFailed(false);
        }, 2000);
        const options = payload && {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, t('errors:validationError'));
            setBackendErrors(payload.validationErrors);
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
      } else if (payload) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
        setOriginUser(payload);
        const parsedUser = {
          ...payload,
          managesUsers: payload.userPermissions?.managesUsers,
          receiveClaimsEmails: payload.userPermissions?.receiveClaimsEmails,
          receiveSurveysEmails: payload.userPermissions?.receiveSurveysEmails,
          imminentRiskEmails: payload.userPermissions?.imminentRiskEmails,
          administerClaims: payload.userPermissions?.administerClaims,
          administerClaimReports: payload.userPermissions?.administerClaimReports,
        };
        delete parsedUser.userPermissions;
        setUser(parsedUser);
        dispatch(
          enqueueSnackbar({
            message: t('notifications:userEdited', {
              name: getFullName(values),
            }),
            options: {
              variant: SNACKBAR_VARIANTS.success,
            },
          }),
        );
      }
      setSubmitting(false);
    }
  };

  return user ? (
    <Formik
      initialValues={{
        ...user,
      }}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={editUser}
    >
      {({ isValid, values, handleSubmit, isSubmitting, dirty }) => {
        return (
          <ContentWrapper
            titleText={t('titles:editUser')}
            actions={
              <>
                <BackButton />
                <RoundedButton
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={!isValid || isSubmitting || !dirty}
                  onClick={handleSubmit}
                  isLoading={loading}
                  isSuccess={isSuccess}
                  isFailed={isFailed}
                >
                  {t('btn:save')}
                </RoundedButton>
              </>
            }
          >
            <UserForm
              values={values}
              backendErrors={backendErrors}
              setBackendErrors={setBackendErrors}
            />
          </ContentWrapper>
        );
      }}
    </Formik>
  ) : (
    <ContentWrapper>
      <Loader fullHeight />
    </ContentWrapper>
  );
};

export default UserEdit;
