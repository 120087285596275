import React, { useContext, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import cx from 'classnames';
import { AppRouteContext } from 'contexts';
import FormikTextField from 'components/FormikFields/FormikTextField';
import AuthFormWrapper from 'components/Wrappers/AuthFormWrapper';
import { routes } from 'config/routes';
import RoundedButton from 'components/Shared/RoundedButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  login as loginReduxAction,
  logout,
} from '../../redux_store/reducer/reducers/userReducer/api';
import { REQUEST_STATUS } from '../../redux_store/reducer/reducers/userReducer/constants';
import {
  setIsLoginStatus,
  setUser,
} from '../../redux_store/reducer/reducers/userReducer/userReducer';
import { useNavigate } from 'react-router-dom';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { INFO_DURATION } from '../../contexts/NotificationsProvider';

const useStyles = makeStyles((theme) => ({
  btn: {
    minWidth: 150,
  },
  loginBtn: {
    margin: theme.spacing(1, 0, 2, 0),
  },
}));

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['btn', 'forms', 'errors', 'titles']);
  const { setAppRoute } = useContext(AppRouteContext);
  const { isLoginStatus, isLoginErrors } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const login = async (values) => {
    dispatch(loginReduxAction(values));
  };

  const pageLoad = () => {
    const hash = window.location.hash;
    if (hash === "#logout" || hash === "#auto_logout") {
      setAppRoute({ path: routes.login.path, removeQueryString: true });
      navigate(routes.login.path)
      dispatch(logout());
      dispatch(setUser({}));
      let n_text = ''
      let n_variant = ''
      switch (hash) {
        case "#auto_logout": {
          n_text = t('notifications:autoLogout');
          n_variant = SNACKBAR_VARIANTS.info;
          break
        }
        case "#logout": {
          n_text = t('notifications:logout401');
          n_variant = SNACKBAR_VARIANTS.warning;
          break
        }
        default: {break}
      }
      dispatch(
        enqueueSnackbar({
          message: n_text,
          options: {
            _key: 'logout',
            persist: true,
            variant: n_variant,
            autoHideDuration: INFO_DURATION,
            preventDuplicate: true,
          },
        }),
      )
    }
  }

  useEffect(() => {
    pageLoad()
  }, []);

  useEffect(() => {
    if (isLoginStatus === REQUEST_STATUS.failed ||isLoginStatus === REQUEST_STATUS.succeeded) {
      setTimeout(() => {
        dispatch(setIsLoginStatus(REQUEST_STATUS.idle));
      }, 2000);
    }
  }, [isLoginStatus]);

  const handlePasswordReset = () => {
    setAppRoute({
      path: routes.passwordReset.path,
      removeQueryString: true,
    });
  };

  return (
    <AuthFormWrapper>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={login}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <FormikTextField
              type="email"
              name="email"
              label={t('forms:email')}
              autoComplete="email"
              autoFocus
              backendError={isLoginErrors.email}
              margin="normal"
              required
            />
            <FormikTextField
              type="password"
              name="password"
              label={t('forms:password')}
              autoComplete="current-password"
              backendError={isLoginErrors.password}
              margin="normal"
              required
            />

            <Grid container direction="column" alignItems="center">
              <RoundedButton
                type="submit"
                variant="contained"
                color="primary"
                loaderColor="white"
                className={cx(classes.loginBtn, classes.btn)}
                disabled={!isValid || isSubmitting || !dirty}
                isFailed={isLoginStatus === REQUEST_STATUS.failed}
                isLoading={isLoginStatus === REQUEST_STATUS.loading}
                isSuccess={isLoginStatus === REQUEST_STATUS.succeeded}
              >
                {t('btn:login')}
              </RoundedButton>

              <RoundedButton
                className={classes.btn}
                color="secondary"
                onClick={handlePasswordReset}
              >
                {t('btn:forgotPassword')}
              </RoundedButton>
            </Grid>
          </Form>
        )}
      </Formik>
    </AuthFormWrapper>
  );
};

export default Login;
