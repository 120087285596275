import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import cx from 'classnames';
import Timer from '@material-ui/icons/Timer';
import CancelIcon from '@material-ui/icons/Cancel';
import CalendarPopover from '../../FormikFields/Calendar/CalendarPopover';
import { getFormattedDate } from '../../../helpers/localize';
import { getDateFromMinuteOffset } from '../../../helpers/dateHelper';
import Select from '../../FormikFields/ComplexSelect/Select';
import useMetadataUpdate from '../../../hooks/useMetadataUpdate';
import { useQuery } from 'react-fetching-library';

const useStyles = makeStyles(() => ({
  breadcrumbsContainer: {
    borderBottom: '1px solid #8E8E8E',
    display: 'flex',
    justifyContent: 'space-between',
    height: 20,
    width: 350,
  },
  breadcrumbs: {
    backgroundColor: 'white',
    padding: 10,
    color: '#8E8E8E',
    fontWeight: 500,
    transition: 'color 0.5s ease',
  },
  activeBreadcrumbs: {
    color: '#00A8FF',
  },
  selectAppointmentProvider: {
    padding: '8px 0',
  },
  doneBreadcrumbs: {
    color: 'black',
  },
  datepickerWrapper: {
    padding: '3px 12px',
    borderRadius: 8,
    border: `1px solid rgba(0, 0, 0, 0.23)`,
    height: 40,

    '& .MuiIconButton-root': {
      padding: '0px !important',
    },
  },
  datepickerLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '.8rem',
    fontWeight: 400,
    lineHeight: 1,
    letterSpacing: '0.00938em',
    backgroundColor: '#FFFFFF',
    position: 'absolute',
    top: -12,
    left: 9,
    padding: 4,
    zIndex: 1,
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  appointmentContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    flexDirection: 'column',
    marginBottom: 20,
  },
  gap: {
    marginBottom: 10,
  },
  marginLeft: {
    marginLeft: -20,
  },
  timePicker: {
    padding: 0,

    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 16,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 2,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(0px, 0px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(0px, 0px) scale(1)',
    },
  },
  scheduledInputsDate: {
    maxWidth: 180,
    marginRight: 20,
  },
  scheduledInputsTime: {
    maxWidth: 180,
  },
  relativeContainer: {
    position: 'relative',
  },
  scheduledOptionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    gap: 16,
    marginRight: 16,
  },
  icon: {
    width: 15,
    height: 15,
  },
  iconPadding: {
    padding: 8,
  },
  inputPadding: {
    padding: '10px 0 !important',
  },
  hideDataPicker: {
    maxHeight: 0,
    transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
    opacity: 0,
    minWidth: 450,
    marginTop: 16,
  },
  showDataPicker: {
    maxHeight: 500,
    opacity: 1,
    transition: 'max-height 0.5s ease-in, opacity 0.5s ease-in',
  },
  secondaryTitle: {
    fontSize: '1.2rem',
    color: '#00A8FF',
  },
}));

const SendingOptions = ({ sendingDataError, values, patientUUID, accountUuid }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms', 'errors', 'notifications']);
  const [selectedTime, handleTimeChange] = useState(null);
  const [selectedAppointmentTime, handleAppointmentTimeChange] = useState(null);
  const [appointmentProviders, setAppointmentProviders] = useState(null);

  const sendingOptions = {
    sendNow: t('forms:sendNow'),
    scheduleForLater: t('forms:scheduleForLater'),
  };

  const { query: queryInfo, loading: loadingClaimInfo } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'GET',
        endpoint: `/accounts/${accountUuid}/patients/${patientUUID}/claims/lookup-info`,
      },
      false,
    ],
  );

  useEffect(() => {
    if (queryInfo && accountUuid && patientUUID) {
      queryInfo().then(({ error, payload }) => {
        if (!error) {
          if (payload.appointmentProviders) {
            const newProviders = payload.appointmentProviders.filter((provider) => !!provider).map(
              (provider) => {
                return {
                  label: provider,
                  value: provider,
                };
              },
            );
            setAppointmentProviders(newProviders);
          }
        }
      });
    }
  }, [queryInfo, accountUuid, patientUUID]);


  useEffect(() => {
    const date = getDateFromMinuteOffset(values.scheduleDeliverTime);
    handleTimeChange(date);
  }, [values.scheduleDeliverTime]);

  useEffect(() => {
    if (!!values.appointmentTime) {
      const date = getDateFromMinuteOffset(values.appointmentTime);
      handleAppointmentTimeChange(date);
    }
  }, [values.appointmentTime]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  return (
    <>
      <div required className={classes.appointmentContainer}>
        <Grid item>
          <Typography className={classes.secondaryTitle}>
            {t('forms:appointmentTimestamp')}
          </Typography>
        </Grid>
        <Grid
          container
          direction='row'
          className={cx(classes.hideDataPicker, {
            [classes.showDataPicker]: true,
          })}
        >
          <div className={classes.scheduledInputsDate}>
            <Field type='data' name='appointmentDate'>
              {({ field: dateField, form }) => {
                return (
                  <div
                    style={{
                      position: 'relative',
                    }}
                  >
                    <FormLabel
                      {...{
                        className: classes.datepickerLabel,
                      }}
                    >
                      {t('forms:appointmentDate')}
                    </FormLabel>
                    <div className={classes.datepickerWrapper}>
                      <CalendarPopover
                        width={100}
                        shouldRange={false}
                        onChange={(newDate) => {
                          if (!newDate) {
                            form.setFieldValue('appointmentDate', '');
                          } else {
                            form.setFieldValue(
                              'appointmentDate',
                              getFormattedDate(newDate),
                            );
                          }
                        }}
                        minDate={today}
                        date={
                          !dateField.value
                            ? ''
                            : new Date(dateField.value)
                        }
                      />
                    </div>
                  </div>
                );
              }}
            </Field>
          </div>
          <div className={classes.scheduledInputsTime}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Grid
                container
                justifyContent='space-around'
                className={classes.relativeContainer}
              >
                <Field name='appointmentTime' type='time'>
                  {({ field, form }) => (
                    <div
                      style={{
                        position: 'relative',
                      }}
                    >
                      <KeyboardTimePicker
                        label={t('forms:appointmentTime')}
                        mask='--:-- --'
                        inputVariant='outlined'
                        className={cx(
                          classes.timePicker,
                          classes.fullWidth,
                        )}
                        placeholder='__:__ _M'
                        autoOk
                        value={selectedAppointmentTime}
                        name={field.name}
                        onChange={(e) => {
                          // eslint-disable-next-line no-underscore-dangle
                          if (e && e._isValid) {
                            form.setFieldValue(
                              'appointmentTime',
                              // eslint-disable-next-line no-underscore-dangle
                              e._d.getHours() * 60 +
                              // eslint-disable-next-line no-underscore-dangle
                              e._d.getMinutes(),
                            );
                          } else {
                            form.setFieldValue(
                              'appointmentTime',
                              'invalid_value',
                            );
                          }
                          handleAppointmentTimeChange(e);
                        }}
                        keyboardIcon={
                          <Timer
                            color='primary'
                            className={classes.icon}
                          />
                        }
                        InputLabelProps={{
                          classes: {
                            root: classes.datepickerLabel,
                          },
                        }}
                        InputProps={{
                          classes: {
                            input: classes.inputPadding,
                          },
                          endAdornment: (
                            <IconButton
                              className={classes.iconPadding}
                              onClick={() => {
                                form.setFieldValue(
                                  'appointmentTime',
                                  'invalid_value',
                                );
                                handleAppointmentTimeChange(null);
                              }}
                            >
                              <CancelIcon className={classes.icon} />
                            </IconButton>
                          ),
                        }}
                        InputAdornmentProps={{
                          position: 'start',
                        }}
                      />
                    </div>
                  )}
                </Field>
              </Grid>
            </MuiPickersUtilsProvider>
          </div>
        </Grid>
        <FormHelperText error className={classes.formError}>
          {sendingDataError}
        </FormHelperText>
      </div>
      {
        accountUuid && patientUUID && <Grid item>
          <Typography className={classes.secondaryTitle}>
            {t('forms:selectAppointmentProvider')}
          </Typography>
          <div  className={classes.selectAppointmentProvider}>
            <Field type='data' name='appointmentProvider'>
              {({ field, form }) => {
                return (
                  <Select
                    filterValue={field.value}
                    initDataLoading={loadingClaimInfo}
                    disabled={!appointmentProviders || loadingClaimInfo}
                    required={false}
                    dataKey='appointmentProvider'
                    columnData={{
                      isFilterable: false,
                      options: appointmentProviders,
                    }}
                    onChange={(key, value) => {
                      form.setFieldValue(
                        key,
                        value,
                      );
                    }}
                    label={`${t('forms:appointmentProvider')}`}
                    labelWidth={78}
                    className={cx(classes.margin0, classes.fullWidth)}
                  />
                );
              }}
            </Field>

          </div>
        </Grid>
      }
      <Field type='text' name='sendingOption'>
        {({ field, form }) => {
          return (
            <FormControl required className={classes.center}>
              <Grid item>
                <Typography className={classes.secondaryTitle}>
                  {t('forms:scheduleDeliverData')}
                </Typography>
              </Grid>
              <RadioGroup
                name={field.name}
                value={field.value}
                onChange={field.onChange}
                className={cx(classes.center)}
                color='primary'
              >
                <FormControlLabel
                  color='primary'
                  value='sendNow'
                  control={<Radio />}
                  label={sendingOptions.sendNow}
                />
                <FormControlLabel
                  color='primary'
                  value='scheduleForLater'
                  control={<Radio />}
                  label={sendingOptions.scheduleForLater}
                  className={cx(classes.gap)}
                />
                <Grid
                  container
                  direction='row'
                  className={cx(classes.hideDataPicker, {
                    [classes.showDataPicker]: field.value === 'scheduleForLater',
                  })}
                >
                  <div className={classes.scheduledInputsDate}>
                    <Field type='data' name='scheduledDate'>
                      {({ field: dateField }) => {
                        return (
                          <div
                            style={{
                              position: 'relative',
                            }}
                          >
                            <FormLabel
                              {...{
                                className: classes.datepickerLabel,
                              }}
                            >
                              {t('forms:scheduleDeliverDate')}
                            </FormLabel>
                            <div className={classes.datepickerWrapper}>
                              <CalendarPopover
                                width={100}
                                shouldRange={false}
                                onChange={(newDate) => {
                                  if (!newDate) {
                                    form.setFieldValue('scheduledDate', '');
                                  } else {
                                    form.setFieldValue(
                                      'scheduledDate',
                                      getFormattedDate(newDate),
                                    );
                                  }
                                }}
                                minDate={tomorrow}
                                date={
                                  !dateField.value
                                    ? ''
                                    : new Date(dateField.value)
                                }
                              />
                            </div>
                          </div>
                        );
                      }}
                    </Field>
                  </div>
                  <div className={classes.scheduledInputsTime}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <Grid
                        container
                        justifyContent='space-around'
                        className={classes.relativeContainer}
                      >
                        <Field name='scheduleDeliverTime' type='time'>
                          {({ _field, _form }) => (
                            <div
                              style={{
                                position: 'relative',
                              }}
                            >
                              <KeyboardTimePicker
                                label={t('forms:scheduleDeliverTime')}
                                mask='--:-- --'
                                inputVariant='outlined'
                                className={cx(
                                  classes.timePicker,
                                  classes.fullWidth,
                                )}
                                required
                                placeholder='__:__ _M'
                                autoOk
                                value={selectedTime}
                                name={field.name}
                                onChange={(e) => {
                                  // eslint-disable-next-line no-underscore-dangle
                                  if (e && e._isValid) {
                                    form.setFieldValue(
                                      'scheduleDeliverTime',
                                      // eslint-disable-next-line no-underscore-dangle
                                      e._d.getHours() * 60 +
                                      // eslint-disable-next-line no-underscore-dangle
                                      e._d.getMinutes(),
                                    );
                                  } else {
                                    form.setFieldValue(
                                      'scheduleDeliverTime',
                                      'invalid_value',
                                    );
                                  }
                                  handleTimeChange(e);
                                }}
                                keyboardIcon={
                                  <Timer
                                    color='primary'
                                    className={classes.icon}
                                  />
                                }
                                InputLabelProps={{
                                  classes: {
                                    root: classes.datepickerLabel,
                                  },
                                }}
                                InputProps={{
                                  classes: {
                                    input: classes.inputPadding,
                                  },
                                  endAdornment: (
                                    <IconButton
                                      className={classes.iconPadding}
                                      onClick={() => {
                                        form.setFieldValue(
                                          'scheduleDeliverTime',
                                          'invalid_value',
                                        );
                                        handleTimeChange(null);
                                      }}
                                    >
                                      <CancelIcon className={classes.icon} />
                                    </IconButton>
                                  ),
                                }}
                                InputAdornmentProps={{
                                  position: 'start',
                                }}
                              />
                            </div>
                          )}
                        </Field>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
                <FormHelperText error className={classes.formError}>
                  {sendingDataError}
                </FormHelperText>
              </RadioGroup>
            </FormControl>
          );
        }}
      </Field>
    </>
  );
};

SendingOptions.propTypes = {
  sendingDataError: PropTypes.string,
  values: PropTypes.shape({
    phone: PropTypes.string,
    email: PropTypes.string,
    scheduleDeliverTime: PropTypes.string,
  }).isRequired,
};

SendingOptions.defaultProps = {
  sendingDataError: null,
};

export default SendingOptions;
