import React from 'react';
import i18next from 'i18next';

export const CHART_TYPES = {
  BAR: 'bar',
  PIE: 'pie',
  LINE: 'line',
};

export const CHARTS = [
  {
    key: 'ageRange',
    chartType: CHART_TYPES.PIE,
  },
  {
    key: 'generalHealth',
    chartType: CHART_TYPES.BAR,
  },
  {
    key: 'allergicRhinitis',
    chartType: CHART_TYPES.LINE,
  },
  {
    key: 'asthma',
    chartType: CHART_TYPES.BAR,
  },
  {
    key: 'allergicConjunctivitis',
    chartType: CHART_TYPES.PIE,
  },
  {
    key: 'allergicUrticara',
    chartType: CHART_TYPES.BAR,
  },
  {
    key: 'symptomSeveritySnot22',
    chartType: CHART_TYPES.BAR,
  },
  {
    key: 'physicalActivity',
    chartType: CHART_TYPES.BAR,
  },
  {
    key: 'fruitVegetableConsumption',
    chartType: CHART_TYPES.BAR,
  },
  {
    key: 'tobaccoUse',
    chartType: CHART_TYPES.BAR,
  },
];

export const assessmentsAlertsColumns = [
  {
    width: 100,
    minWidth: 100,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'firstName',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'lastName',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:assessment'),
    dataKey: 'surveyName',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:completedTimestamp'),
    dataKey: 'completedDate',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
];

export const healthSeekingBehaviorColumns = [
  {
    width: 100,
    minWidth: 100,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'firstName',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'lastName',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 300,
    label: i18next.t('tables:helpRequested'),
    dataKey: 'helpRequested',
    flexGrow: 3,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
  {
    width: 100,
    label: i18next.t('tables:completedTimestamp'),
    dataKey: 'completedDate',
    flexGrow: 1,
    columnData: {
      shouldRange: false,
      isFilterable: false,
    },
  },
];

export const ANIMATION_TIMEOUT = 1500;
