import React, { useEffect, useState } from 'react';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from 'react-fetching-library';
import showServerError from '../../../../helpers/showError';
import {
  Box, Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, fade, FormControlLabel, makeStyles,
} from '@material-ui/core';
import RoundedButton from '../../../Shared/RoundedButton';
import Typography from '@material-ui/core/Typography';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../../redux_store/reducer/reducers/notificationsReducer';
import { capitalizeFirstLetter } from '../../../../helpers/dateHelper';

const useStyles = makeStyles((theme) => ({
  actions: {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 3, 3, 3),
  },
  btn: {
    marginLeft: theme.spacing(2),
  },
  title: {
    fontWeight: 500,
    paddingTop: theme.spacing(2),
  },
  subtitle: {
    fontSize: '1rem',
  },
  checkbox: {
    marginBottom: 16,
  },
  descriptionContainer: {
    borderTop: `1px solid ${fade('#000000', 0.1)}`,
    marginTop: 24,
    marginBottom: 32,
    fontSize: '1rem',
  },
  descriptionRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5em',
    padding: 16,
    paddingLeft: 0,
    alignItems: 'baseline',
    borderBottom: `1px solid ${fade('#000000', 0.1)}`,
  },
  descriptionRowName: {
    flex: 1,
    textAlign: 'end',
    color: '#888888',
    width: 260,
    minWidth: 260,
  },
  descriptionRowValue: {
    flex: 1,
    fontWeight: '500',
  },
}));

const GenerateClaimReportModal = ({
                                    selectedItems,
                                    accountUuid,
                                    accountBusinessName,
                                    reloadData,
                                    resetSelected,
                                    isOpenStatusDialog,
                                    setOpenStatusDialog,
                                  }) => {
  const {
    en: {
      tables,
      errors,
      dialogs,
      btn,
    },
  } = useSelector((state) => state.i18n);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [downloadXlsxFile, toggleDownloadXlsxFile] = useState(true);

  const getActionList = (accountUUID, patch) => ({
    method: 'POST',
    endpoint: `/accounts/${accountUUID}/reportables/actions`,
    credentials: 'include',
    body: patch,
    responseType: 'blob',
  });

  const { mutate: getActions, loading: isStatusLoading } = useMutation(getActionList);

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
  };

  const downloadResultExcelFile = (payload) => {
    const url = window.URL.createObjectURL(new Blob([payload], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
    const link = document.createElement('a');
    link.href = url;
    const today = new Date();
    const options = {
      month: 'long',
    };
    const month = capitalizeFirstLetter(today.toLocaleString('en-GB', options)).slice(0, 3);
    const fileName = `${accountBusinessName} ISP Services ${month} ${today.getFullYear()}.xlsx`;
    console.log(fileName);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleChangeSelectedStatus = async () => {
    const data = {
      'statusAction': 'reported',
      'reportablesUUIDs': selectedItems,
    };

    const { payload, error, status } = await getActions(accountUuid, data);
    if (!error) {
      if (payload && payload.size > 0 && downloadXlsxFile) {
        await downloadResultExcelFile(payload);
      }
      dispatch(
        enqueueSnackbar({
          message: dialogs.reportHasBeenGenerated,
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      setTimeout(() => {
        resetSelected(true);
        reloadData(true);
        handleCloseStatusDialog();
      }, 200);
    } else {
      switch (status) {
        case 422:
        case 500: {
          const obj = JSON.parse(await payload.text());
          if (obj.validationErrors) {
            Object.keys(obj.validationErrors).forEach(key =>
              showServerError(dispatch, obj.validationErrors[key]));
            break;
          }
          showServerError(dispatch, errors.validationError);
          break;
        }
        default: {
          showServerError(dispatch, errors.unknownError);
          break;
        }
      }
      handleCloseStatusDialog();
    }
  };

  return <Dialog open={isOpenStatusDialog}
                 onClose={isStatusLoading ? null : handleCloseStatusDialog}>
    <DialogTitle disableTypography>
      <Typography variant='h5' className={classes.title}>
        {dialogs.generateClaimsReport}
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Typography component='div' color={'secondary'}>
        {dialogs.generateClaimsReportDescription1}
        <Box fontWeight='600' display='inline'>
          {dialogs.generateClaimsReportDescription2}
        </Box>
        {dialogs.generateClaimsReportDescription3}
      </Typography>
      <div className={classes.descriptionContainer}>
        <div className={classes.descriptionRow}>
          <div className={classes.descriptionRowName}>{tables.accountName}</div>
          <div className={classes.descriptionRowValue}>{accountBusinessName}</div>
        </div>
        <div className={classes.descriptionRow}>
          <div className={classes.descriptionRowName}>{tables.numberOfClaimCPTLineItems}</div>
          <div className={classes.descriptionRowValue}>{selectedItems.length.toString()}</div>
        </div>
      </div>
    </DialogContent>
    <DialogContent>
      <Typography className={classes.subtitle} variant='h6'>{dialogs.additionalOptions}</Typography>
      <FormControlLabel
        disabled={isStatusLoading}
        control={
          <Checkbox
            name={'downloadXlsxFile'}
            checked={downloadXlsxFile}
            disabled={isStatusLoading}
            color='primary'
            onChange={(e, checked) => {
              toggleDownloadXlsxFile(!downloadXlsxFile);
            }}
          />
        }
        className={classes.checkbox}
        label={dialogs.downloadXlsxFile}
      />
    </DialogContent>
    <DialogActions classes={{ root: classes.actions }}>
      <RoundedButton
        onClick={handleCloseStatusDialog}
        variant='contained'
        color='secondary'
        size='small'
        disabled={isStatusLoading}
      >
        {btn.cancel}
      </RoundedButton>
      <RoundedButton
        onClick={handleChangeSelectedStatus}
        isLoading={isStatusLoading}
        disabled={isStatusLoading}
        variant='contained'
        color='primary'
        size='small'
        className={classes.btn}
        LoadingWrapperProps={{
          LoaderProps: {
            disableShrink: true,
          },
        }}
      >
        {btn.verifyAndGenerate}
      </RoundedButton>
    </DialogActions>
  </Dialog>;
};

export default GenerateClaimReportModal;
