import React, { useContext } from 'react';
import { List, makeStyles } from '@material-ui/core';
import NavItem from 'components/Navigation/NavItem';
import { checkPermission } from 'helpers/checkPermissions';
import { UserContext } from 'contexts';
import { useSelector } from 'react-redux';
import NAV_ITEMS, { NAV_ADMIN_ITEMS } from './constants';

const useStyles = makeStyles((theme) => ({
  navigation: {
    marginTop: theme.spacing(5),
    width: '100%',
  },
  driver: {
    borderTop: `1px solid ${theme.palette.common.white}`,
    margin: theme.spacing(2),
    opacity: 0.5,
  },
}));

const NavWrapper = () => {
  const classes = useStyles();
  const {
    metadata: { currentAccountPermissions },
  } = useSelector((state) => state.user);
  const { disabledFeatures } = useSelector((state) => state.appSettings);

  let hasAdminPermissions = false;

  NAV_ADMIN_ITEMS.forEach((navItem) => {
    const isAllowed =
      checkPermission(
        currentAccountPermissions,
        navItem.route.additional.permission,
      ) && !disabledFeatures.includes(navItem.route.additional.disabledId);

    if (isAllowed) {
      hasAdminPermissions = true;
    }
  });

  const getMenuItems = (navItems) => {
    return navItems.map((navItem) => {
      const isAllowed =
        checkPermission(
          currentAccountPermissions,
          navItem.route.additional.permission,
        ) && !disabledFeatures.includes(navItem.route.additional.disabledId);
      return isAllowed ? <NavItem key={navItem.id} item={navItem} /> : null;
    });
  };

  return (
    <>
      <List component="nav" className={classes.navigation}>
        {getMenuItems(NAV_ITEMS)}
        {hasAdminPermissions && (
          <>
            <div className={classes.driver} />
            {getMenuItems(NAV_ADMIN_ITEMS)}
          </>
        )}
      </List>
    </>
  );
};

export default NavWrapper;
