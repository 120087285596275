import PropTypes from 'prop-types';
import React from 'react';
import 'intersection-observer';
import './styles.css';
import { SURVEYS_QUESTION_TYPES } from '../constants';
import GridSelect from './GridSelectView';
import MultiSelect from './MultiSelectView';
import SingleSelect from './SingleSelectView';

const ReportViewTable = ({ surveyList, counterColoredAnswers }) => {
  return (
    <>
      {surveyList.length > 0 && (
        <table className="styled_table">
          <tr className="list-break-marker">
            <th> </th>
            <th>
              <div className="dot green-dot-bgcolor">
                {counterColoredAnswers.success}
              </div>
            </th>
            <th>
              <div className="dot orange-dot-bgcolor">
                {counterColoredAnswers.warning}
              </div>
            </th>
            <th>
              <div className="dot red-dot-bgcolor">
                {counterColoredAnswers.error}
              </div>
            </th>
            <th>
              <div className="dot black-dot-bgcolor">
                {counterColoredAnswers.info}
              </div>
            </th>
          </tr>
          {surveyList.length > 0 &&
            surveyList.map((survey) => {
              if (
                survey.itemType === SURVEYS_QUESTION_TYPES.GridSelect ||
                survey.itemType === SURVEYS_QUESTION_TYPES.GridNumericalSelector
              ) {
                return <GridSelect survey={survey} />;
              }
              if (
                survey.itemType ===
                SURVEYS_QUESTION_TYPES.BasicNumericalSelector
              ) {
                return <SingleSelect survey={survey} />;
              }
              if (
                survey.values ||
                survey.itemType === SURVEYS_QUESTION_TYPES.MultiSelect
              ) {
                return <MultiSelect survey={survey} />;
              }
              return <SingleSelect survey={survey} />;
            })}
        </table>
      )}
    </>
  );
};

ReportViewTable.propTypes = {
  surveyList: PropTypes.arrayOf(PropTypes.object),
  counterColoredAnswers: PropTypes.shape({
    success: PropTypes.number,
    warning: PropTypes.number,
    error: PropTypes.number,
    info: PropTypes.number,
  }).isRequired,
};

ReportViewTable.defaultProps = {
  surveyList: [],
};

export default ReportViewTable;
