import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import RoundedButton from 'components/Shared/RoundedButton';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';

const useStyles = makeStyles((theme) => ({
  addNewButton: {
    height: '100%',
    marginRight: theme.spacing(2),
  },
}));

const PatientActions = ({
  isLoading,
  toggleAssessmentModal,
  toggleResourceModal,
  togglePatientEditModal,
  setOrderPatientUuid,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);

  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);

  const handleEditPatient = () => {
    togglePatientEditModal(true);
  };

  const handleResourceCreate = () => {
    toggleResourceModal(true);
  };

  const handleAssessmentCreate = () => {
    const hasPermission = checkPermission(
      paramAccountUuid === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_SURVEY_CREATE,
    );
    if (hasPermission) {
      toggleAssessmentModal(true);
    } else {
      dispatch(
        enqueueSnackbar({
          message: t('notifications:notPermissionForCreatingAssessment'),
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
    }
  };

  const handleOrderCreate = () => {
    const hasPermission = checkPermission(
      paramAccountUuid === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_CREATE,
    );
    if (hasPermission) {
      setOrderPatientUuid(paramsPatientUuid);
    } else {
      dispatch(
        enqueueSnackbar({
          message: t('notifications:notPermissionForCreatingOrder'),
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
    }
  };

  const isResourcesAvailable = checkPermission(
    currentAccountPermissions,
    permissions.GET_RESOURCES,
  );

  return (
    <>
      <RoundedButton
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleAssessmentCreate}
        className={classes.addNewButton}
      >
        {t('btn:createAssessment')}
      </RoundedButton>
      {isResourcesAvailable && (
        <RoundedButton
          variant="outlined"
          color="primary"
          size="small"
          onClick={handleResourceCreate}
          className={classes.addNewButton}
        >
          {t('btn:createResource')}
        </RoundedButton>
      )}
      <RoundedButton
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleOrderCreate}
        className={classes.addNewButton}
        isLoading={isLoading}
      >
        {t('btn:createOrder')}
      </RoundedButton>
      <RoundedButton
        variant="outlined"
        color="primary"
        size="small"
        onClick={handleEditPatient}
        className={classes.addNewButton}
      >
        {t('btn:editPatient')}
      </RoundedButton>
    </>
  );
};

PatientActions.propTypes = {
  toggleAssessmentModal: PropTypes.func.isRequired,
  toggleResourceModal: PropTypes.func.isRequired,
  togglePatientEditModal: PropTypes.func.isRequired,
  setOrderPatientUuid: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default PatientActions;
