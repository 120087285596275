import i18next from 'i18next';

const genderItems = [
  { label: i18next.t('forms:female'), value: 'female' },
  {
    label: i18next.t('forms:male'),
    value: 'male',
  },
];

export default genderItems;

export const FAKE_PATIENT = {
  accountCustomTags: [],
  uuid: '',
  accountUUID: '',
  accountBusinessName: '',
  accountCustomIdentifier: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  moreTitle: '',
};

export const PATIENT_LANGUAGE = {
  ENGLISH: 'english',
  SPANISH: 'spanish',
  UNKNOWN: 'unknown',
};

export const PATIENT_LANGUAGE_SELECTOR_OPTIONS = [
  {
    label: 'English',
    value: PATIENT_LANGUAGE.ENGLISH,
  },
  {
    label: 'Spanish',
    value: PATIENT_LANGUAGE.SPANISH,
  },
  {
    label: 'Unknown',
    value: PATIENT_LANGUAGE.UNKNOWN,
  },
];
