import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import { compare } from 'fast-json-patch';
import isEqual from 'lodash/isEqual';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import OrderForm from 'components/Orders/OrderForm';
import { AppRouteContext } from 'contexts';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import BackButton from 'components/Shared/BackButton';
import { checkPermission } from 'helpers/checkPermissions';
import permissions from 'config/permissions';
import { orderStatuses } from 'components/Orders/constants';
import { useDispatch, useSelector } from 'react-redux';
import { routes } from '../../config/routes';
import EmptyPageLoader from '../Shared/EmptyPageLoader';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';

const OrderEdit = () => {
  const { accountUuid, orderUuid, patientUuid } = useParams();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const [order, setOrder] = useState();
  const dispatch = useDispatch();
  const {
    user,
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const { payload: metadata, error: metadataError } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'GET',
        endpoint: `/accounts/${accountUuid}/patients/${patientUuid}/create-order-metadata`,
      },
    ],
  );

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}/orders/${orderUuid}`,
    },
    false,
  ]);

  useEffect(() => {
    const hasPermission = checkPermission(
      accountUuid === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.ORDER_PATCH,
    );
    if (!hasPermission) {
      setAppRoute({
        path: routes.patientsList.path,
        title: routes.patientsList.additional.title,
      });
      dispatch(
        enqueueSnackbar({
          message: t('notifications:notPermissionForEditingOrder'),
          options: {
            variant: SNACKBAR_VARIANTS.warning,
          },
        }),
      );
    }
  }, []);

  useEffect(() => {
    query().then(({ error, payload }) => {
      if (!error) {
        setOrder(payload);
      }
    });
  }, [query]);

  const orderEditAction = (patches) => ({
    method: 'PATCH',
    endpoint: `/accounts/${accountUuid}/orders/${orderUuid}`,
    body: patches,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [orderEditAction]);

  const editOrder = async (values, { setSubmitting, setFieldValue }) => {
    const {
      slitLowDose,
      slitLowDoseQuantity,
      slitMaintenance,
      slitMaintenanceQuantity,
    } = values;

    const formattedValues = {
      ...values,
      slitLowDoseQuantity:
        slitLowDose && slitLowDoseQuantity ? slitLowDoseQuantity : 0,
      slitMaintenanceQuantity:
        slitMaintenance && slitMaintenanceQuantity
          ? slitMaintenanceQuantity
          : 0,
    };
    const orderCopy = { ...order };
    delete formattedValues.scitAllergens; // will be patched manually
    delete formattedValues.slitLowDose; // internal usage
    delete formattedValues.slitMaintenance; // internal usage
    delete orderCopy.scitAllergens;
    delete orderCopy.slitLowDose;
    delete orderCopy.slitMaintenance;

    // compare all the values except deleted one
    const patches = compare(orderCopy, formattedValues);
    // create a patch for an array manually
    if (!isEqual(order.scitAllergens, values.scitAllergens)) {
      patches.push({
        op: 'replace',
        path: '/scitAllergens',
        value: values.scitAllergens,
      });
    }

    if (patches.length) {
      setBackendErrors({});
      const { payload, error, status } = await mutate(patches);
      if (error) {
        setIsFailed(true);
        setTimeout(() => {
          setIsFailed(false);
        }, 2000);
        if (!slitLowDose) {
          setFieldValue('slitLowDoseQuantity', 0);
        }
        if (!slitMaintenance) {
          setFieldValue('slitMaintenanceQuantity', 0);
        }

        const options = payload && {
          correlationUUID: payload.correlationUUID,
          userUUID: user.uuid,
        };
        switch (status) {
          case 422: {
            showServerError(dispatch, t('errors:validationError'));
            setBackendErrors(payload.validationErrors);
            break;
          }
          default: {
            showServerError(dispatch, '', options);
            break;
          }
        }
      } else if (payload) {
        setIsSuccess(true);
        setTimeout(() => {
          setIsSuccess(false);
        }, 2000);
        setOrder(payload);
        dispatch(
          enqueueSnackbar({
            message: t('notifications:orderEdited', {
              name: metadata.patientInfo.name,
            }),
            options: {
              variant: SNACKBAR_VARIANTS.success,
            },
          }),
        );
      }
      setSubmitting(false);
    }
  };

  if (!order || !metadata || metadataError) {
    return <EmptyPageLoader />;
  }

  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );
  const isEditable =
    isDistributionSponsor ||
    (orderStatuses.find((orderStatus) => orderStatus.value === order.status) &&
      orderStatuses.find((orderStatus) => orderStatus.value === order.status)
        .allowEditOrder);

  return (
    <Formik
      initialValues={{
        ...order,
        slitLowDose: !!order.slitLowDoseQuantity,
        slitMaintenance: !!order.slitMaintenanceQuantity,
      }}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={editOrder}
    >
      {({ isValid, isSubmitting, dirty, handleSubmit, values }) => (
        <ContentWrapper
          titleText={t('titles:editOrder')}
          actions={
            <>
              <BackButton />
              <RoundedButton
                type="submit"
                variant="contained"
                color="primary"
                size="small"
                disabled={!isValid || isSubmitting || !dirty || !isEditable}
                onClick={handleSubmit}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
              >
                {t('btn:save')}
              </RoundedButton>
            </>
          }
        >
          <OrderForm
            values={values}
            metadata={metadata}
            backendErrors={backendErrors}
            isEditable={isEditable}
          />
        </ContentWrapper>
      )}
    </Formik>
  );
};

export default OrderEdit;
