import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  selectMenu: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(0, 168, 255, 0.5)',

    '&::before': {
      borderBottom: '0 !important',
    },
    '&::after': {
      borderBottom: '0 !important',
      backgroundColor: '#f5f5f5',
    },
    '& .MuiSelect-iconOpen': {
      transform: 'none',
    },

    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'inherit',
    },

    minHeight: 0,
    minWidth: 0,
    padding: 0,
    width: 49,
    height: 49,
    marginLeft: 18,
  },
  selectorMenu: {
    padding: 3,
    fontWeight: 400,
    width: 200,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    fontSize: '1rem',
    boxSizing: 'border-box',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  selectMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
  },
  selectMenuTitle: {
    height: 33,
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 14,
    marginBottom: 8,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  iconBtn: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 0,
    minWidth: 0,
    padding: '0 8px',
    height: 49,
    marginLeft: 18,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 12,
    transform: 'rotate( -180deg )',
  },
  driver: {
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    opacity: 0.2,
  },
}));
