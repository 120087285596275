import PropTypes from 'prop-types';
import React from 'react';
import FilterListIcon from '@material-ui/icons/FilterList';
import {
  Box,
  makeStyles,
  Grid,
  Paper,
  Chip,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
import getScrollBarWidth from 'helpers/getScrollBarWidth';
import { useTranslation } from 'react-i18next';
import Skeleton from '@material-ui/lab/Skeleton';
import { FAKE_TITLE_DATA } from '../Shared/FakeDataGenerator';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1.25, 3, 0, 3),
    width: '100%',
    height: '100%',
    borderRadius: 0,
    overflow: 'hidden',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',

    '@media print': {
      display: 'none',
    },
  },
  box: {
    maxHeight: 49,

    '@media print': {
      display: 'none',
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.colors.lightBlue,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[1],

    '@media print': {
      display: 'none',
    },
  },
  titleItem: {
    position: 'relative',
    marginLeft: 4,
    fontWeight: 500,

    '@media print': {
      display: 'none',
    },
  },
  titleText: {
    fontSize: 36,

    '@media print': {
      display: 'none',
    },
  },
  titleTextBox: {
    display: 'flex',
    maxHeight: 35,
    alignItems: 'center',
    maxWidth: 700,

    '@media print': {
      display: 'none',
    },
  },
  titleCount: {
    height: 21,
    fontSize: 11,
    color: theme.palette.common.white,
    backgroundColor: theme.colors.lightBlue,

    '@media print': {
      display: 'none',
    },
  },
  additionChip: {
    backgroundColor: theme.colors.green,

    '@media print': {
      display: 'none',
    },
  },
  countFiltered: {
    backgroundColor: theme.colors.blue,

    '@media print': {
      display: 'none',
    },
  },
  filterIcon: {
    height: 17,
    width: 15,
    marginRight: 3,
    color: theme.palette.common.white,

    '@media print': {
      display: 'none',
    },
  },
  titleCountLabel: {
    padding: theme.spacing(0, 1),

    '@media print': {
      display: 'none',
    },
  },
  underTitleBox: {
    fontSize: 15,
    letterSpacing: 0,
    display: 'flex',
    color: theme.colors.blue2,
    marginLeft: 4,

    '@media print': {
      display: 'none',
    },
  },
  actions: (props) => ({
    height: 49,
    paddingRight: theme.spacing(props.scrollBarWidth),
    display: 'flex',

    [theme.breakpoints.between(0, 1315)]: {
      paddingTop: theme.spacing(props.hasTopPaddingForSmallScreen ? 2 : 0),
    },
  }),
  linkBox: {
    cursor: 'pointer',
  },
  backLink: {
    fontSize: 13,
    textTransform: 'uppercase',
    color: theme.colors.lightBlue,
    fontWeight: 'bold',
  },
  backLinkIcon: {
    fill: theme.colors.lightBlue,
  },
  hideCount: {
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
  },
  count: {
    visibility: 'visible',
    opacity: 1,
    transition: 'visibility 0s, opacity 0.5s linear',
  },
  container: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    '--webkit-overflow-scrolling': 'touch', // possible solution for Safari chopping scrolling issue
    '--webkit-transform': 'translate3d(0, 0, 0)', // founded here https://stackoverflow.com/questions/24156709/choppy-scrolling-in-safari
  },
}));

const ContentWrapper = ({
                          title,
                          titleText,
                          additionTitleButton,
                          count,
                          additionChip,
                          actions,
                          children,
                          className,
                          countFiltered,
                          additionalNode,
                          underTitleText,
                          scrollBarSupport,
                          titleClassName,
                          underTitleTextStyle,
                          actionsStyle,
                          hasTopPaddingForSmallScreen,
                          ...rest
                        }) => {
  const scrollBarWidth = scrollBarSupport ? getScrollBarWidth() + 1.5 : 0;
  const classes = useStyles({ scrollBarWidth, hasTopPaddingForSmallScreen });
  const { t } = useTranslation(['tables']);

  return (
    <Paper className={cx(classes.paper, className)} {...rest}>
      <Grid
        container
        justify='space-between'
        alignItems='center'
        direction={{ xs: 'column', lg: 'row' }}
      >
        <Grid
          item
        >
          <Grid container justify='flex-start' alignItems='center'>
            {title && (
              <div className={cx(classes.titleBox, titleClassName)}>
                {title}
              </div>
            )}
            {titleText === FAKE_TITLE_DATA && (
              <Grid
                item
                className={classes.titleTextBox}
                style={{
                  // width: '100%',
                  minWidth: 250,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <Skeleton variant='text' height={56} />
                </div>
              </Grid>
            )}
            {titleText && titleText !== FAKE_TITLE_DATA && (
              <Grid item className={classes.titleTextBox}>
                <Typography
                  noWrap
                  className={cx(classes.titleItem, classes.titleText)}
                >
                  {titleText}
                </Typography>
              </Grid>
            )}
            {additionTitleButton}
            {count !== undefined && count !== null && (
              <Grid
                item
                className={cx(classes.hideCount, {
                  [classes.count]: count !== FAKE_TITLE_DATA,
                })}
              >
                <Chip
                  label={
                    countFiltered ? (
                      <Box display='flex' alignItems='center' fontSize={12}>
                        <FilterListIcon className={classes.filterIcon} />
                        {count.toLocaleString('en-US')
                        }
                      </Box>
                    ) : (
                      count.toLocaleString('en-US')
                    )
                  }
                  classes={{
                    root: cx(
                      classes.titleItem,
                      classes.titleCount,
                      countFiltered && classes.countFiltered,
                    ),
                    label: classes.titleCountLabel,
                  }}
                />
              </Grid>
            )}
            {additionChip !== undefined && additionChip !== null && (
              <Grid
                item
                className={cx(classes.hideCount, {
                  [classes.count]: additionChip !== FAKE_TITLE_DATA,
                })}
              >
                <Chip
                  label={`${additionChip} ${t('tables:completionRate')}`}
                  classes={{
                    root: cx(
                      classes.titleItem,
                      classes.titleCount,
                      !!additionChip && classes.additionChip,
                    ),
                    label: classes.titleCountLabel,
                  }}
                />
              </Grid>
            )}
          </Grid>
          {underTitleText && (
            <Typography className={cx(classes.underTitleBox, underTitleTextStyle)}>
              {underTitleText}
            </Typography>
          )}
        </Grid>
        {!!actions && (
          <Grid item className={cx(classes.actions, actionsStyle)}>
            {actions}
          </Grid>
        )}
      </Grid>
      {!!additionalNode && additionalNode}
      <div
        className={cx(classes.container)}
      >
        {children}
      </div>
    </Paper>
  );
};

ContentWrapper.propTypes = {
  title: PropTypes.node,
  additionTitleButton: PropTypes.node,
  titleText: PropTypes.string,
  additionalNode: PropTypes.node,
  underTitleText: PropTypes.node,
  count: PropTypes.number,
  additionChip: PropTypes.number,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  countFiltered: PropTypes.bool,
  scrollBarSupport: PropTypes.bool,
  className: PropTypes.string,
  underTitleTextStyle: PropTypes.string,
  actionsStyle: PropTypes.string,
  titleClassName: PropTypes.string,
};

ContentWrapper.defaultProps = {
  title: undefined,
  additionTitleButton: undefined,
  titleText: undefined,
  count: undefined,
  additionChip: undefined,
  countFiltered: undefined,
  additionalNode: undefined,
  underTitleText: undefined,
  actions: undefined,
  className: undefined,
  underTitleTextStyle: undefined,
  actionsStyle: undefined,
  scrollBarSupport: false,
  titleClassName: undefined,
};

export default ContentWrapper;
