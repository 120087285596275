import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'formik';
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  Divider,
} from '@material-ui/core';

import FormikTextField from 'components/FormikFields/FormikTextField';
import Radio from 'components/Shared/Radio';
import USER_ROLES from 'components/Users/constants';
import cx from 'classnames';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchAccounts } from '../../redux_store/reducer/reducers/accountsReducer';
import ContentWrapper from '../Wrappers/ContentWrapper';
import Loader from '../Shared/Loader';
import { REQUEST_STATUS } from '../../redux_store/reducer/reducers/userReducer/constants';
import AllowedAccountsSection from './AllowedAccountsSection';
import AllowedAccountsSectionModal from './AllowedAccountsModal';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
    transition: 'opacity 0.3s ease-in',
    opacity: 1,
  },
  hideCheckbox: {
    opacity: 0,
    transition: 'opacity 0.3s ease-in',
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  roleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  roleLabel: {
    margin: theme.spacing(0, 3, 0, 2),
  },
  error: {
    color: theme.palette.error.main,
    marginLeft: 14,
    marginRight: 14,
    marginBottom: 8,
    fontSize: '0.75rem',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
  selectorContainer: {
    marginTop: 20,
  },
}));

const UserForm = ({ values, backendErrors, setBackendErrors }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation(['forms', 'titles']);
  const isProvider = values.role === USER_ROLES.PROVIDER;
  const { accounts, status } = useSelector((state) => state.accounts);
  const [openAllowedAccountsModal, toggleAllowedAccountsModal] = useState(false);

  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const { accountUuid: paramAccountUuid } = useParams();

  const isUserBelongsToCurrentAccount =
    paramAccountUuid === currentAccount.uuid;
  let isDistributionSponsor = checkPermission(
    isUserBelongsToCurrentAccount
      ? currentAccountPermissions
      : childAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  if (!isUserBelongsToCurrentAccount) {
    if (!accounts) {
      if (status !== REQUEST_STATUS.loading) {
        dispatch(fetchAccounts());
      }
    } else {
      const account = accounts.find(
        (account) => account.uuid === paramAccountUuid,
      );
      isDistributionSponsor = account?.accountType === 'distributionSponsor';
    }
  }

  return (
    <>
      {status !== REQUEST_STATUS.loading ? (
        <Form>
          <Field name='role' type='text'>
            {({ form }) => (
              <AllowedAccountsSectionModal
                values={values}
                changeAllowedAccounts={(val) => {
                  form.setFieldValue('allowedAccounts', val);
                }}
                openAllowedAccountsModal={openAllowedAccountsModal}
                toggleAllowedAccountsModal={toggleAllowedAccountsModal}
              />)}
          </Field>
          <Grid container direction='column' alignItems='flex-start'>
            <Typography className={classes.title}>
              {t('forms:userDetails')}:
            </Typography>
            <FormikTextField
              type='text'
              name='firstName'
              label={t('forms:firstName')}
              backendError={backendErrors.firstName}
              required
              disabled={values.doesUserExist}
            />
            <FormikTextField
              type='text'
              name='lastName'
              label={t('forms:lastName')}
              backendError={backendErrors.lastName}
              required
              disabled={values.doesUserExist}
            />
            <FormikTextField
              type='text'
              name='email'
              label={t('forms:email')}
              required
              backendError={backendErrors.email}
            />

            <Divider className={classes.divider} />
            <Typography className={classes.title}>
              {t('forms:userRoleDetails')}:
            </Typography>
            <Field name='role' type='text'>
              {({ field }) => (
                <FormControl
                  required
                  className={classes.roleContainer}
                  error={backendErrors.role}
                >
                  <FormLabel className={classes.roleLabel}>
                    {t('forms:role')}
                  </FormLabel>
                  <RadioGroup
                    aria-label='role'
                    name='role'
                    value={field.value}
                    onChange={field.onChange}
                    row
                  >
                    <FormControlLabel
                      value={USER_ROLES.PROVIDER}
                      control={<Radio color='primary' />}
                      label={t('forms:provider')}
                      labelPlacement='end'
                    />
                    <FormControlLabel
                      value={USER_ROLES.ASSISTANT}
                      control={<Radio color='primary' />}
                      label={t('forms:assistant')}
                      labelPlacement='end'
                    />
                  </RadioGroup>
                </FormControl>
              )}
            </Field>

            {isProvider && (
              <>
                <FormikTextField
                  type='text'
                  name='providerTitle'
                  label={t('forms:title')}
                  backendError={backendErrors.providerTitle}
                  required
                />
                <FormikTextField
                  type='text'
                  name='providerNPINumber'
                  label={t('forms:npiNumber')}
                  backendError={backendErrors.providerNPINumber}
                  required
                />
              </>
            )}
            <Field name='managesUsers' type='checkbox'>
              {({ field, form }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color='primary'
                      onChange={(e, checked) => {
                        form.setFieldValue(field.name, checked);
                        setBackendErrors({
                          ...backendErrors,
                          'userPermissions.managesUsers': null,
                        });
                      }}
                    />
                  }
                  className={classes.checkbox}
                  label={t('titles:manageUsers')}
                />
              )}
            </Field>
            {!!backendErrors['userPermissions.managesUsers'] && (
              <Typography className={cx(classes.error)}>
                {backendErrors['userPermissions.managesUsers']}
              </Typography>
            )}
            <Field name='administerClaimReports' type='checkbox'>
              {({ field, form }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color='primary'
                      onChange={(e, checked) => {
                        form.setFieldValue(field.name, checked);
                        setBackendErrors({
                          ...backendErrors,
                          'userPermissions.administerClaimReports': null,
                        });
                      }}
                    />
                  }
                  className={classes.checkbox}
                  label={t('titles:administerClaimReports')}
                />
              )}
            </Field>
            {!!backendErrors['userPermissions.administerClaimReports'] && (
              <Typography className={cx(classes.error)}>
                {backendErrors['userPermissions.administerClaimReports']}
              </Typography>
            )}
            <Field name='administerClaims' type='checkbox'>
              {({ field, form }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color='primary'
                      onChange={(e, checked) => {
                        if (!checked) {
                          form.setFieldValue('receiveClaimsEmails', false);
                          setBackendErrors({
                            ...backendErrors,
                            'userPermissions.administerClaims': null,
                          });
                        }
                        form.setFieldValue(field.name, checked);
                      }}
                    />
                  }
                  className={classes.checkbox}
                  label={t('titles:administerClaims')}
                />
              )}
            </Field>
            {!!backendErrors['userPermissions.administerClaims'] && (
              <Typography className={cx(classes.error)}>
                {backendErrors['userPermissions.administerClaims']}
              </Typography>
            )}
            <Field name='imminentRiskEmails' type='checkbox'>
              {({ field, form }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={field.name}
                      checked={field.value}
                      color='primary'
                      onChange={(e, checked) => {
                        if (!checked) {
                          form.setFieldValue('receiveClaimsEmails', false);
                          setBackendErrors({
                            ...backendErrors,
                            'userPermissions.imminentRiskEmails': null,
                          });
                        }
                        form.setFieldValue(field.name, checked);
                      }}
                    />
                  }
                  className={classes.checkbox}
                  label={t('titles:imminentRiskEmails')}
                />
              )}
            </Field>
            {!!backendErrors['userPermissions.imminentRiskEmails'] && (
              <Typography className={cx(classes.error)}>
                {backendErrors['userPermissions.imminentRiskEmails']}
              </Typography>
            )}
            {!isDistributionSponsor && (
              <Field name='receiveSurveysEmails' type='checkbox'>
                {({ field, form }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={field.name}
                        checked={field.value}
                        color='primary'
                        onChange={(e, checked) => {
                          form.setFieldValue(field.name, checked);
                          setBackendErrors({
                            ...backendErrors,
                            'userPermissions.receiveSurveysEmails': null,
                          });
                        }}
                      />
                    }
                    className={cx(classes.checkbox)}
                    label={t('titles:receiveSurveysEmails')}
                  />
                )}
              </Field>
            )}
            {!!backendErrors['userPermissions.receiveSurveysEmails'] && (
              <Typography className={cx(classes.error)}>
                {backendErrors['userPermissions.receiveSurveysEmails']}
              </Typography>
            )}
            {!isDistributionSponsor && (
              <Field name='receiveClaimsEmails' type='checkbox'>
                {({ field, form }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!values.administerClaims}
                        name={field.name}
                        checked={field.value}
                        color='primary'
                        onChange={(e, checked) => {
                          form.setFieldValue(field.name, checked);
                          setBackendErrors({
                            ...backendErrors,
                            'userPermissions.receiveClaimsEmails': null,
                          });
                        }}
                      />
                    }
                    className={cx(classes.checkbox, {
                      [classes.hideCheckbox]: !values.administerClaims,
                    })}
                    label={t('titles:receiveClaimsEmails')}
                  />
                )}
              </Field>
            )}
            {!!backendErrors['userPermissions.receiveClaimsEmails'] && (
              <Typography className={cx(classes.error)}>
                {backendErrors['userPermissions.receiveClaimsEmails']}
              </Typography>
            )}
            {
              (isDistributionSponsor || true) && <AllowedAccountsSection
                values={values}
                backendErrors={backendErrors}
                toggleAllowedAccountsModal={toggleAllowedAccountsModal}
              />
            }
          </Grid>
        </Form>
      ) : (
        <ContentWrapper>
          <Loader fullHeight />
        </ContentWrapper>
      )}
    </>
  );
};

UserForm.propTypes = {
  values: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    providerTitle: PropTypes.string,
    providerNPINumber: PropTypes.string,
    managesUsers: PropTypes.bool,
    administerClaims: PropTypes.bool,
    administerClaimReports: PropTypes.bool,
    receiveClaimsEmails: PropTypes.bool,
    doesUserExist: PropTypes.bool,
  }).isRequired,
  setBackendErrors: PropTypes.func.isRequired,
  backendErrors: PropTypes.shape({
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
    providerTitle: PropTypes.string,
    providerNPINumber: PropTypes.string,
    'userPermissions.managesUsers': PropTypes.string,
    'userPermissions.administerClaimReports': PropTypes.string,
    'userPermissions.administerClaims': PropTypes.string,
    'userPermissions.receiveClaimsEmails': PropTypes.string,
    'userPermissions.receiveSurveysEmails': PropTypes.string,
  }),
};

UserForm.defaultProps = {
  backendErrors: undefined,
};

export default UserForm;
