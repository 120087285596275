import React  from 'react';
import {
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { useSelector } from 'react-redux';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '0 6px',
    fontSize: 12,
  },
  automationIcon: {
    color: theme.colors.gray4,
    position: 'absolute',
    width: 20,
    right: -10,
    top: -10,
    '&$checked': {
      color: theme.colors.gray4,
    },
  },
  button: {
    height: 36,
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    position: 'relative',
    width: 195,
  },
}));

const ToggleButtons = ({
                         alignment,
                         handleChange,
                       }) => {
  const classes = useStyles();
  const { en: { tables } } = useSelector((state) => state.i18n);

  return <div className={classes.toggleContainer}>
    <Grid item>
      <ToggleButtonGroup size='small' value={alignment} exclusive onChange={handleChange}>
        <ToggleButton value='left' classes={{ root: classes.button }}>
          <Typography className={classes.title}>
            {tables.preFilter}
          </Typography>
        </ToggleButton>
        <ToggleButton value='right' classes={{ root: classes.button }}>
          <Typography className={classes.title}>
            {tables.all}
          </Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Grid>
    <Tooltip
      title={tables.preFilterHint}
      style={{ zIndex: 100 }}>
      <InfoIcon className={classes.automationIcon} />
    </Tooltip>
  </div>;
};

export default ToggleButtons;
