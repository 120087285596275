import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, makeStyles, Grid, Link } from '@material-ui/core';

import { routes } from 'config/routes';
import { AppRouteContext } from 'contexts';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  link: {
    fontSize: 16,
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
}));

const NotFound = () => {
  const { t } = useTranslation('btn', 'titles');
  const classes = useStyles();
  const { user } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);

  const handleLoginClick = () => {
    setAppRoute({ path: routes.login.path, removeQueryString: true });
  };

  const handleDashboardClick = () => {
    setAppRoute({ path: routes.root.path });
  };

  return (
    <Grid container direction="column" alignItems="center" justify="center">
      <Typography className={classes.title}>{t('titles:notFound')}</Typography>
      {user ? (
        <Link className={classes.link} onClick={handleDashboardClick}>
          {t('titles:dashboard')}
        </Link>
      ) : (
        <Link className={classes.link} onClick={handleLoginClick}>
          {t('btn:login')}
        </Link>
      )}
    </Grid>
  );
};

export default NotFound;
