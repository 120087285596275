import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import Cpts from './../CptModal/CptsTable/Cpts';
import Modal from '../../../../../../Shared/Modal';
import { useSelector } from 'react-redux';
import { Field } from 'formik';
import useMetadataUpdate from '../../../../../../../hooks/useMetadataUpdate';
import { useQuery } from 'react-fetching-library';
import smoothscroll from 'smoothscroll-polyfill';
import cx from 'classnames';
import CptsFooter from './CptsTable/widgets/CptsFooter';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const useStyles = makeStyles((theme) => ({
  modal: {
    maxWidth: 1000,
  },
  text: {
    marginLeft: 16,
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  selectAllCheckBox: {
    padding: 0,
    marginRight: 46,
    marginLeft: 6,
  },
  tableContainer: {
    border: '1px solid rgba(0, 0, 0, 0.37);',
    minHeight: 520,
    height: 520,
    maxHeight: 520,
    width: 650,
    padding: 0,
    borderRadius: 10,
    overflow: 'auto',
    position: 'relative',
    marginBottom: 20,
    boxShadow: 'inset 0px -71px 56px -18px rgba(255,255,255,1)',
    paddingBottom: 56,
  },
  tableContainerNoCpt: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addNewButton: {
    position: 'absolute',
    bottom: 8,
    right: 24,
    left: 24,
    border: '1px solid white',
    backgroundColor: theme.colors.green,
    color: 'white',

    '&:hover': {
      backgroundColor: theme.colors.green,
      opacity: 0.8,
    },
  },
}));


const CptsModal = ({
                     values,
                     toggleCptsModal,
                     openCptsModal,
                     backendErrors,
                     validateField,
                     setErrors,
                   }) => {
  const { t } = useTranslation([
    'titles',
    'btn',
  ]);
  const { en: { btn } } = useSelector((state) => state.i18n);
  const classes = useStyles();
  const [cptCodes, setCptCodes] = useState();
  const [resetFiltersTrigger, setResetFiltersTrigger] = useState(false);
  const [cpts, setCpts] = useState([]);

  const { query: _cptCodesFromApi, loading: loadingCptCodes } = useMetadataUpdate(
    useQuery,
    [
      {
        method: 'GET',
        endpoint: `/cpts`,
      },
      false,
    ],
  );

  useEffect(() => {
    setInitValues();
  }, [values.ispConfiguration]);

  useEffect(() => {
    if (openCptsModal) {
      setInitValues();
    }
  }, [openCptsModal]);

  const setInitValues = () => {
    if (
      !cpts.length
      && values.ispConfiguration
      && values.ispConfiguration.services
      && values.ispConfiguration.services.length
    ) {
      const dataWithIds = values.ispConfiguration.services.map((item, index) => {
        return {
          ...item,
          uuid: new Date().getTime() + index,
          index: index,
          rowLines: 2.3,
        };
      });
      setCpts(dataWithIds);
    }
  };

  useEffect(() => {
    if (_cptCodesFromApi && !cptCodes) {
      _cptCodesFromApi().then(({ error, payload }) => {
        if (!error) {
          if (payload) {
            const newCptCodes = payload.map((name) => {
              return {
                label: name,
                value: name,
              };
            });
            setCptCodes(newCptCodes);
          }
        }
      });
    }
  }, [_cptCodesFromApi]);

  const closeModal = () => {
    setCpts([]);
    toggleCptsModal(false);
    setResetFiltersTrigger(true);
  };

  const updateFieldValue = (index, key, newValue) => {
    setCpts(cpts.map(item => {
      if (item.index === index) {
        return {
          ...item,
          [key]: newValue,
        };
      } else {
        return item;
      }
    }));
  };

  const addItem = (form) => {
    const newCpts = [
      ...cpts,
      {
        cptCode: '',
        uuid: new Date().getTime() + cpts.length,
        index: cpts.length,
        rowLines: 2.3,
        price: '',
      },
    ];
    setCpts(newCpts);
    form.setFieldValue('ispConfiguration.services', newCpts.map(({ cptCode, price }) => {
      return {
        cptCode: cptCode,
        price: price,
      };
    }));
    scrollToBottom();
  };

  function scrollToBottom() {
    const table = document.getElementById('cpts_table_id');
    if (table) {
      const lastRow = table.lastChild;
      setTimeout(() => {
        lastRow.scroll({ top: lastRow.scrollHeight, behavior: 'smooth' });
      }, 450);
    }
  }

  const cptLength = useMemo(
    () => values.ispConfiguration
      && values.ispConfiguration.services
      && values.ispConfiguration.services.length || 0,
    [values?.ispConfiguration?.services?.length],
  );

  return <Modal
    open={openCptsModal}
    classes={{ paper: classes.modal }}
    title={t('titles:accountCptCodes')}
    handleClose={closeModal}
    disableBackdropClick={true}
  >
    <Box p={3} display='flex' flexDirection={'column'}>
      <Box p={2} display='flex' flexDirection={'row'} alignItems={'center'}
           justifyContent='space-between'>
        <Typography className={classes.text}>
          {t('forms:cptsTotalNumber', {
            number: cptLength,
          })}
        </Typography>
      </Box>
      <Field type={'text'} name={'ispConfiguration.services'}>
        {({ form }) => {
          const noCpt = !cptLength;
          return <>
            <div className={cx(classes.tableContainer, {
              [classes.tableContainerNoCpt]: noCpt,
            })}>
              <Cpts
                backendErrors={backendErrors}
                setErrors={setErrors}
                validateField={validateField}
                cpts={cpts}
                setCpts={setCpts}
                cptCodes={cptCodes}
                loadingCptCodes={loadingCptCodes}
                resetFiltersTrigger={resetFiltersTrigger}
                setResetFiltersTrigger={setResetFiltersTrigger}
                updateFieldValue={updateFieldValue}
              />
              <Button onClick={() => addItem(form)} className={classes.addNewButton}>
                {btn.addNew}
              </Button>
            </div>

            <CptsFooter
              values={values}
              form={form}
              closeModal={closeModal}
              backendErrors={backendErrors}
              setErrors={setErrors}
            />
          </>;
        }}
      </Field>
    </Box>
  </Modal>;
};

export default CptsModal;
