import i18next from 'i18next';
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg';
import { ReactComponent as DelayedIcon } from 'assets/icons/delayed.svg';
import { ReactComponent as ShippedIcon } from 'assets/icons/shipped.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/processing.svg';
import { ReactComponent as CancelledIcon } from 'assets/icons/cancelled.svg';
import { ReactComponent as HoldIcon } from 'assets/icons/hold.svg';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';
import { getFormattedDate } from '../../helpers/localize';

export const orderTypes = {
  SLIT: 'slit',
  SCIT: 'scit',
};

export const deprecatedScitItems = [
  { label: i18next.t('scit:buildUp46'), value: 'fourtySixDosePerUnitBuildUp' },
];

export const scitItems = [
  { label: i18next.t('scit:buildUp50'), value: 'fiftyDosePerUnitBuildUp' },
  { label: i18next.t('scit:stage1'), value: 'tenDosePerUnitStageOne' },
  { label: i18next.t('scit:stage2'), value: 'tenDosePerUnitStageTwo' },
  { label: i18next.t('scit:stage3'), value: 'tenDosePerUnitStageThree' },
  { label: i18next.t('scit:stage4'), value: 'tenDosePerUnitStageFour' },
  {
    label: i18next.t('scit:scitMaintenance'),
    value: 'twelveDosePerUnitSCITMaintenance',
  },
];

export const slitItems = [
  { label: i18next.t('slit:oneMonthLowDose'), value: 'oneMonthLowDose' },
  {
    label: i18next.t('slit:oneMonthSLITMaintenance'),
    value: 'oneMonthSLITMaintenance',
  },
];

export const allergenItems = [
  { label: i18next.t('allergens:grasses'), value: 'grasses' },
  { label: i18next.t('allergens:weeds'), value: 'weeds' },
  { label: i18next.t('allergens:trees'), value: 'trees' },
  { label: i18next.t('allergens:cat'), value: 'cat' },
  { label: i18next.t('allergens:dog'), value: 'dog' },
  { label: i18next.t('allergens:dustMites'), value: 'dustMites' },
  { label: i18next.t('allergens:cockroach'), value: 'cockroach' },
  { label: i18next.t('allergens:mold'), value: 'mold' },
];

export const formulaItems = [
  { label: i18next.t('formulas:outdoor'), value: 'outdoor' },
  { label: i18next.t('formulas:indoor2'), value: 'indoorAllergenMixtureTwo' },
  { label: i18next.t('formulas:indoor3'), value: 'indoorAllergenMixtureThree' },
  { label: i18next.t('formulas:indoor4'), value: 'indoorAllergenMixtureFour' },
  { label: i18next.t('formulas:mold'), value: 'mold' },
  { label: i18next.t('formulas:environment'), value: 'environmentMixture' },
];

export const ORDER_STATUSES = {
  pending: 'pending',
  processing: 'processing',
  shipped: 'shipped',
  onHold: 'onHold',
  delayed: 'delayed',
  canceled: 'canceled',
  sending: 'sending',
  compounding: 'compounding',
};

export const ORDER_ACTIONS = {
  putOnHold: 'putOnHold',
  revertToPending: 'revertToPending',
  processManually: 'processManually',
  cancel: 'cancel',
  sendToPharmacy: 'sendToPharmacy',
  delay: 'delay',
  ship: 'ship',
};

export const orderStatusesActions = [
  {
    label: i18next.t('tables:putOnHold'),
    value: ORDER_ACTIONS.putOnHold,
    icon: HoldIcon,
    allowEditOrder: true,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:revertToPending'),
    value: ORDER_ACTIONS.revertToPending,
    icon: PendingIcon,
    allowEditOrder: true,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:processManually'),
    value: ORDER_ACTIONS.processManually,
    icon: ProcessingIcon,
    allowEditOrder: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:cancel'),
    value: ORDER_ACTIONS.cancel,
    icon: CancelledIcon,
    allowEditOrder: false,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:delay'),
    value: ORDER_ACTIONS.delay,
    icon: DelayedIcon,
    allowEditOrder: true,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:ship'),
    value: ORDER_ACTIONS.ship,
    icon: ShippedIcon,
    allowEditOrder: false,
    enabledForNonSponsors: false,
  },
];

export const orderStatuses = [
  // ToDO: edit icon, allowEditOrder and enabledForNonSponsors
  {
    label: i18next.t('tables:sending'),
    value: ORDER_STATUSES.sending,
    icon: PendingIcon,
    allowEditOrder: true,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:pending'),
    value: ORDER_STATUSES.pending,
    icon: PendingIcon,
    allowEditOrder: true,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:processing'),
    value: ORDER_STATUSES.processing,
    icon: ProcessingIcon,
    allowEditOrder: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:shipped'),
    value: ORDER_STATUSES.shipped,
    icon: ShippedIcon,
    allowEditOrder: false,
    enabledForNonSponsors: false,
    addDivider: true,
  },
  {
    label: i18next.t('tables:onHold'),
    value: ORDER_STATUSES.onHold,
    icon: HoldIcon,
    allowEditOrder: true,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:delayed'),
    value: ORDER_STATUSES.delayed,
    icon: DelayedIcon,
    allowEditOrder: true,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:canceled'),
    value: ORDER_STATUSES.canceled,
    icon: CancelledIcon,
    allowEditOrder: false,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:compounding'),
    value: ORDER_STATUSES.compounding,
    icon: CancelledIcon,
    allowEditOrder: false,
    enabledForNonSponsors: true,
  },
];

export const ORDER_STATUSES_OPTIONS = orderStatuses;

export const ORDER_TYPES = [...deprecatedScitItems, ...scitItems, ...slitItems];

export const FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'orderDate',
  'patientMrnNumber',
  'patientDateOfBirth',
  'patientFirstName',
  'patientLastName',
  'orderType',
  'orderFormula',
  'orderStatus',
  'patientUUID',
];

export const ORDERS_FOR_PATIENT_FIELDS = [
  'accountBusinessName',
  'orderDate',
  'orderType',
  'patientMrnNumber',
  'patientFirstName',
  'patientLastName',
  'orderFormula',
  'orderStatus',
  'patientUUID',
];

export const CSV_FIELDS = [
  'organization',
  'firstName',
  'lastName',
  'dob',
  'gender',
  'allergies',
  'patientPhone',
  'patientAddress',
  'clinician',
  'npi',
  'shipTo',
  'category',
  'type',
  'formula',
  'qty',
  'geography',
  'date',
  'comments',
];

export const CSV_HEADERS = CSV_FIELDS.map((csvField) => ({
  key: csvField,
  label: i18next.t(`ordersCsv:${csvField}`),
}));

export const CSV_FILENAME = `Orders ${getFormattedDate(new Date(Date.now()))}.csv`;

export const MAX_ORDERS = 100;

export const GET_ORDER_COLUMNS = (isPatientPage, paramAccountUuid) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 1,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 140,
    label: i18next.t('tables:patientDOB'),
    dataKey: 'patientDateOfBirth',
    hidden: isPatientPage,
  },
  {
    width: isPatientPage ? 250 : 220,
    label: i18next.t('tables:orderType'),
    dataKey: 'orderType',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:orderFormula'),
    dataKey: 'orderFormula',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:orderDate'),
    dataKey: 'orderDate',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:expectedDeliveryDate'),
    dataKey: 'expectedDeliveryDate',
    disableSort: true,
  },
  {
    width: 200,
    label: i18next.t('tables:status'),
    dataKey: 'orderStatus',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT_WITH_DYNAMIC_HINT,
    },
  },
];

export const FAKE_ORDER = {
  accountCustomTags: [],
  uuid: '',
  accountUUID: '',
  accountBusinessName: '',
  accountCustomIdentifier: '',
  patientFirstName: '',
  patientLastName: '',
  patientUUID: '',
  patientDateOfBirth: '',
  orderType: '',
  orderFormula: '',
  orderDate: '',
  orderStatus: '',
  expectedDeliveryDate: '',
  moreTitle: '',
};
