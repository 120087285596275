import React from 'react';
import { Field } from 'formik';
import CurrencyFormat from 'react-currency-format';

const CurrencyFormatInput = ({
                               type,
                               name,
                               backendError,
                               onChange,
                               onFocus,
                               customInput,
                             }) => {
  return (
    <Field type={type} name={name}>
      {({ field, form }) => {
        return <CurrencyFormat
          prefix={'$ '}
          value={field.value}
          customInput={(props) => customInput({
            ...props,
            error: backendError,
            helperText: backendError,
          })}
          fixedDecimalScale={true}
          decimalScale={2}
          onBlur={async (e) => {
            const value = e.target.value.replace('$ ', '');
            await form.setFieldValue(name, value);
            onChange && onChange(name, value);
          }}
          thousandSeparator={','}
          onFocus={onFocus}
        />;
      }}
    </Field>
  );
};

export default CurrencyFormatInput;
