import React, { useState } from 'react';
import { Button, makeStyles, Popover } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/export-icon.svg';
import showServerError from '../../../../helpers/showError';
import RoundedButton from '../../../Shared/RoundedButton';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getEndpoint } from '../../../../helpers/endpoint';
import { useMutation } from 'react-fetching-library';
import { GET_CLAIMS_COLUMNS } from '../../constants';
import { checkPermission } from '../../../../helpers/checkPermissions';
import permissions from '../../../../config/permissions';
import cx from 'classnames';
import { TABLE_KEYS } from '../../../../redux_store/reducer/reducers/tableColumnsReducer';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getFormattedDate } from '../../../../helpers/localize';

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
  },
  contained: {
    color: 'rgba(0, 0, 0, 0.87) !important',
    boxShadow: 'none',
    backgroundColor: '#f5f5f5',
    borderRadius: 0,
    borderRight: '1px solid rgba(3, 31, 66, 0.15);',
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,

    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      boxShadow: 'none',
      backgroundColor: '#f5f5f5',
      borderRadius: 0,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
    },

    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      boxShadow: 'none',
      backgroundColor: '#f5f5f5',
      borderRadius: 0,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      cursor: 'inherit',

      '& .MuiButton-startIcon': {
        animation: `$rotation  1.5s linear infinite`,
      },
    },
  },
  selectMenu: {
    backgroundColor: '#fff',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    border: '1px solid rgba(0, 168, 255, 0.5)',

    '&::before': {
      borderBottom: '0 !important',
    },
    '&::after': {
      borderBottom: '0 !important',
      backgroundColor: '#f5f5f5',
    },
    '& .MuiSelect-iconOpen': {
      transform: 'none',
    },

    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'inherit',
    },

    minHeight: 0,
    minWidth: 0,
    padding: 0,
    width: 49,
    height: 49,
    marginLeft: 18,
  },
  select: {
    backgroundColor: '#f5f5f5 !important',
    minHeight: 0,
    minWidth: 0,
    padding: '0 !important',
    margin: '0 !important',
    width: 0,
    height: 49,
  },
  selectorMenu: {
    padding: 3,
    fontWeight: 400,
    width: 200,
    // height: 36,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    fontSize: '1rem',
    boxSizing: 'border-box',
    lineHeight: 1.5,
    // whiteSpace: 'nowrap',
    letterSpacing: '0.00938em',
  },
  '@keyframes rotation': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(359deg)',
    },
  },
  selectMenuContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
  },
  selectMenuTitle: {
    height: 33,
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 14,
    marginBottom: 8,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  iconBtn: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 0,
    minWidth: 0,
    padding: '0 8px',
    height: 49,
    marginLeft: 18,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 12,
    transform: 'rotate( -180deg )',
  },
  driver: {
    borderTop: `1px solid ${theme.palette.text.secondary}`,
    opacity: 0.2,
  },
}));

const DownloadButton = ({
                          toggleDownloadPdfModal,
                          tableFilter,
                          selectorTypeButton,
                          tableSort,
                        }) => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);

  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const {
    user,
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { listOfHiddenColumns } = useSelector((state) => state.tableColumns);
  const tableKey = paramAccountUuid ? TABLE_KEYS.claimsDS : TABLE_KEYS.claims;

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }`;

  const getClaimsCSV = (fields) => ({
    method: 'GET',
    endpoint: getEndpoint(`${baseEndpoint}/export-claims.csv`, { fields }, tableSort, tableFilter),
    responseType: 'blob',
    headers: {
      'Content-Type': 'text/csv',
    },
  });

  const { mutate: getCSV } = useMutation(getClaimsCSV);

  const FIELDS_FOR_CSV = GET_CLAIMS_COLUMNS(
    isPatientPage,
    paramAccountUuid,
  ).filter(
    ({ permission, hidden }) =>
      checkPermission(currentAccountPermissions, permission) && !hidden,
  ).map((columnData) => columnData.dataKey)
    .filter(value => !(listOfHiddenColumns[tableKey] ?? []).includes(value));

  const hasLoadCSVPermission = checkPermission(
    currentAccountPermissions,
    permissions.ADMIN_PATIENT_IMPORT,
  );

  const [anchorEl, setAnchorEl] = React.useState();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadedCSVFile = async () => {
    handleClose();
    setIsCSVLoading(true);
    const { payload, error, status } = await getCSV(FIELDS_FOR_CSV.join());
    if (error) {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 401: {
          showServerError(dispatch, t('notifications:notPermissionForEditingClaim'));
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload && payload.size > 0) {
      const url = window.URL.createObjectURL(new Blob([payload]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Claims ${getFormattedDate(new Date(Date.now()))}.csv`);
      document.body.appendChild(link);
      link.click();
    }
    setIsCSVLoading(false);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const classes = useStyles();
  return <>{
    hasLoadCSVPermission && <div>
      <RoundedButton
        variant={'outlined'}
        color='primary'
        size='small'
        isLoading={isCSVLoading}
        onClick={async (e) => {
          if (selectorTypeButton) {
            handleClick(e);
            return;
          }
          await downloadedCSVFile();
        }}
        className={classes.iconBtn}
        LoadingWrapperProps={{
          LoaderProps: {
            disableShrink: true,
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingLeft: 4,
          }}
        >
          <ExportIcon className={classes.icon} />
          <div style={{
            textTransform: 'none',
            fontSize: '0.875rem',
            fontWeight: 500,
            lineHeight: 1.75,
            letterSpacing: '0.02857em',
            marginRight: 8,
          }}>
            {t('btn:Download')}
          </div>
          {selectorTypeButton && <ExpandMoreIcon />}
        </div>
      </RoundedButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.selectMenuTitle}>{t('btn:download')}</div>
        <div className={classes.selectMenuContainer}>
          <Button
            className={classes.selectorMenu}
            onClick={() => {
              handleClose();
              toggleDownloadPdfModal();
            }}
          >
            {t('btn:downloadPDF')}
          </Button>
        </div>
        <div className={cx(classes.driver)} />
        <div className={cx(classes.selectMenuContainer)}>
          <Button
            className={classes.selectorMenu}
            onClick={downloadedCSVFile}
          >
            {t('btn:downloadCSV')}
          </Button>
        </div>
      </Popover>
    </div>
  }</>;
};

DownloadButton.propTypes = {};

DownloadButton.defaultProps = {};

export default DownloadButton;
