import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, createRef, useState, useRef } from 'react';
import { KeyboardArrowDown } from '@material-ui/icons';
import {
  Box,
  Typography,
  Collapse,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import cx from 'classnames';
import 'intersection-observer';
import copy from 'copy-to-clipboard';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../../redux_store/reducer/reducers/notificationsReducer';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 2,
    marginLeft: 2,
  },
  icon: {
    color: theme.colors.lightBlue,
    fontSize: 32,
  },
  fakeRef: {
    position: 'absolute',
    top: -theme.spacing(3),
  },
  fakeRefContainer: {
    position: 'relative',
  },
  pointer: {
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[3],
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  copyButton: {
    opacity: 1,
    right: 0,
    transition: 'max-width 0.5s',
    maxWidth: 175,
    fontSize: 11,
    textTransform: 'uppercase',
    color: theme.colors.lightBlue,
    fontWeight: 800,
    letterSpacing: 1,
    borderRadius: 8,
    display: 'flex',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),

    '&:hover': {
      '& $copyIcon': {
        transition: 'fill .05s',
        fill: theme.colors.lightBlue,
      },
    },
  },
  closedStyle: {
    maxWidth: 40,
    transition: 'max-width 0.5s',
  },
  copyIcon: {
    transition: 'fill 1s',
    padding: 0,
    marginRight: 10,
    fill: theme.colors.grey,
  },
  copyTitle: {
    minWidth: 125,
    fontSize: 11,
    textTransform: 'uppercase',
    color: theme.colors.lightBlue,
    fontWeight: 800,
    letterSpacing: 1,
    textAlign: 'left',
  },
  open: {
    transform: 'rotate( 180deg )',
    transition: 'transform 150ms ease',
  },
  close: {
    transform: 'rotate( 360deg )',
    transition: 'transform 150ms ease',
  },
  error: {
    paddingRight: 8,
    paddingLeft: 10,
    color: theme.palette.error.main,
    '&::before': {
      width: 11,
      height: 11,
      content: '""',
      marginRight: 5,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.palette.error.main,
    },
  },
  success: {
    paddingRight: 8,
    paddingLeft: 10,
    color: theme.colors.green,
    '&::before': {
      width: 11,
      height: 11,
      content: '""',
      marginRight: 5,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.colors.green,
    },
  },
  warning: {
    paddingRight: 8,
    paddingLeft: 10,
    color: theme.colors.orange,
    '&::before': {
      width: 11,
      height: 11,
      content: '""',
      marginRight: 5,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.colors.orange,
    },
  },
}));

const HEIGHT_OF_TOP_CONTENT = 230;

const CollapseBlock = ({
  title,
  refs,
  colors,
  setCurrentBlockId,
  pageHeight,
  setOpenBlock,
  minHeight,
  children,
  reportContent,
}) => {
  const classes = useStyles();
  const open = get(refs, `[${title}].open`, false);
  const ref = get(refs, `[${title}].ref`, createRef());
  const currentRef = useRef();

  const addScrollListener = () => {
    const observerConfig = {
      rootMargin: `-${HEIGHT_OF_TOP_CONTENT}px 0px -${
        pageHeight - HEIGHT_OF_TOP_CONTENT
      }px 0px`.toString(), // eslint-disable-line
    };
    let isInit = true;

    const handleIntersection = (entries) => {
      const [entry] = entries;
      if (isInit) {
        isInit = false;
      } else {
        setCurrentBlockId(entry.target.dataset.id);
      }
    };
    const observer = new IntersectionObserver(
      handleIntersection,
      observerConfig,
    );
    observer.observe(currentRef.current);
    return () => observer.disconnect();
  };

  useEffect(() => {
    if (refs) {
      addScrollListener();
    }
  }, [refs, pageHeight]);

  return (
    <Box
      minHeight={
        minHeight && open ? pageHeight - HEIGHT_OF_TOP_CONTENT : 'none'
      }
      className={classes.fakeRefContainer}
    >
      <div ref={ref} data-id={title} className={classes.fakeRef} />
      <Box
        height={20}
        data-id={title}
        ref={currentRef}
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.pointer}
        onClick={() => setOpenBlock(title)}
      >
        <KeyboardArrowDown
          className={cx(classes.icon, {
            [classes.open]: open,
            [classes.close]: !open,
          })}
        />
        <Typography className={classes.title}>{title}</Typography>
        <div
          style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {!!colors.success && (
            <Typography className={cx(classes.success)}>
              {colors.success}
            </Typography>
          )}
          {!!colors.warning && (
            <Typography className={cx(classes.warning)}>
              {colors.warning}
            </Typography>
          )}
          {!!colors.error && (
            <Typography className={cx(classes.error)}>
              {colors.error}
            </Typography>
          )}
          <CopyToClipboardButton
            title={title}
            content={children}
            reportContent={reportContent}
          />
        </div>
      </Box>
      <Collapse in={open} timeout={450}>
        <Box pb="30px" pl="16px">
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

CollapseBlock.propTypes = {
  title: PropTypes.string.isRequired,
  reportContent: PropTypes.string,
  refs: PropTypes.shape({
    ref: PropTypes.shape({
      current: PropTypes.node,
    }),
    open: PropTypes.bool,
  }),
  setCurrentBlockId: PropTypes.func.isRequired,
  setOpenBlock: PropTypes.func.isRequired,
  pageHeight: PropTypes.number,
  minHeight: PropTypes.bool,
  children: PropTypes.node.isRequired,
  colors: PropTypes.shape({
    success: PropTypes.number,
    warning: PropTypes.number,
    error: PropTypes.number,
  }).isRequired,
};

CollapseBlock.defaultProps = {
  pageHeight: 100,
  refs: {},
  minHeight: false,
  reportContent: '',
};

export default CollapseBlock;

function CopyToClipboardButton({ title, content, reportContent }) {
  const classes = useStyles();
  const [isCopyButtonHovered, setIsCopyButtonHovered] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);

  const removeMarkdownSyntax = (text) => {
    return text
      .replaceAll(
        '%%%',
        `

`,
      )
      .replaceAll('**|++|{success}', '')
      .replaceAll('**|++|{warning}', '')
      .replaceAll('**|++|{error}', '')
      .replaceAll('**|++|{info}', '')
      .replaceAll('++**', '')
      .replaceAll('**|', '')
      .replaceAll('**', '')
      .replaceAll('++|{success}', '')
      .replaceAll('++|{warning}', '')
      .replaceAll('++|{error}', '')
      .replaceAll('++|{info}', '')
      .replaceAll('++', '')
      .replace('!{success}', '')
      .replace('!{warning}', '')
      .replace('!{error}', '')
      .replace('!{info}', '')
      .replace('{success}', '')
      .replace('{warning}', '')
      .replace('{error}', '')
      .replace('{info}', '')
      .replaceAll('| -', ' ')
      .replaceAll('|', ' ')
      .replaceAll('*', '')
      .replaceAll('__', '');
  };

  const checkLastCharacter = (word) => {
    return word.charAt(word.length - 1) === ':' ? word : `${word}:`;
  };

  const copyContentToClipboard = (event) => {
    event.stopPropagation();
    let copyText = `${title}`;

    // add reportContent if exist
    if (reportContent) {
      copyText = `${copyText}

${removeMarkdownSyntax(reportContent)}`;
    }

    // add answers list
    let answersList = ``;
    content[content.length - 1].props.surveyList.forEach((currentItem) => {
      // check kind of the question
      if (currentItem.subItems) {
        answersList = `${answersList}
${currentItem.title}`;
        currentItem.subItems.forEach((subItem) => {
          answersList = `${answersList}
${checkLastCharacter(subItem.title)}  ${subItem.value.title}`;
        });
      } else if (currentItem.value) {
        answersList = `${answersList}
${checkLastCharacter(currentItem.title)}  ${currentItem.value.title}`;
      } else {
        let valuesList = ``;
        currentItem.values.forEach((value, i) => {
          valuesList = `${valuesList}${i > 0 ? ', ' : ''}${value.title}`;
        });
        answersList = `${answersList}
${checkLastCharacter(currentItem.title)}  ${
          valuesList.length ? valuesList : 'NONE'
        }`;
      }
    });
    if (answersList) {
      copyText = `${copyText}
${answersList}`;
    }

    copy(copyText);
    dispatch(
      enqueueSnackbar({
        message: t('notifications:copyToClipboard'),
        options: {
          variant: SNACKBAR_VARIANTS.success,
        },
      }),
    );
  };

  return (
    <Box
      sx={{ height: 36 }}
      onMouseEnter={() => setIsCopyButtonHovered(true)}
      onMouseLeave={() => setIsCopyButtonHovered(false)}
    >
      <IconButton
        aria-label="delete"
        className={cx(classes.copyButton, {
          [classes.closedStyle]: !isCopyButtonHovered,
        })}
        size="medium"
        onClick={copyContentToClipboard}
      >
        <FileCopyOutlinedIcon className={classes.copyIcon} fontSize="small" />
        <Typography className={classes.copyTitle}>
          {t('btn:copyToClipboard')}
        </Typography>
      </IconButton>
    </Box>
  );
}
