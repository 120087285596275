export const FILTERING_RULES = {
  exact: 'exact',
  startWith: 'startWith',
  include: 'include',
};

export const SORTING_TYPES = {
  string: 'string',
  number: 'number',
};

export const getFilteredData = (data, filters, filtersRulesByKey = {}) => {
  const noData = !data || data.length === 0;
  const noFilters = Object.values(filters).reduce((accumulator, currentValue) => {
    return accumulator + currentValue.trim().length;
  }, 0).length === 0;

  if (noData) {
    return [];
  } else if (noFilters) {
    return data;
  } else {
    return data.filter((item) => {
      let res = true;
      Object.keys(filters).forEach((key) => {
        if (res) {
          const currentValue = item[key];
          if (!!filters[key]) {
            const filterAsArray = filters[key].split(',');

            if (filterAsArray.length > 1) {
              res = filterAsArray
                .map(item => _checkIsMatch(
                  currentValue,
                  item,
                  filtersRulesByKey[key] || { type: FILTERING_RULES.exact },
                )).some((x) => x);
            } else {
              res = _checkIsMatch(
                currentValue,
                filters[key],
                filtersRulesByKey[key] || { type: FILTERING_RULES.include },
              );
            }
          }
        }
      });
      return res;
    });
  }
};


const _checkIsMatch = (value, filterValue, filterRules = {}) => {
  if (!filterValue) {
    return true;
  }
  const { type, postfix = '' } = filterRules;
  const test = value.toString().toLowerCase();
  const _filterValue = filterValue.toString().toLowerCase();

  switch (type) {
    case FILTERING_RULES.include: {
      return test.includes(_filterValue);
    }
    case FILTERING_RULES.exact: {
      return value.toString() === filterValue || value === (filterValue + postfix);
    }
    case FILTERING_RULES.startWith: {
      return test.startsWith(_filterValue);
    }
  }
};

export const getSortedData = (
  data,
  {
    sortBy,
    sortDirection = 'ASC',
  },
  sortingRules = {
    type: SORTING_TYPES.string,
  }) => {
  if (!data || data.length === 0) {
    return [];
  } else if (!sortBy) {
    return data;
  }
  const { type } = sortingRules[sortBy];
  return data.sort((a, b) => {
    if (sortDirection === 'ASC') {
      if (type === SORTING_TYPES.string) {
        return a[sortBy].toString() > b[sortBy].toString();
      } else if (type === SORTING_TYPES.number) {
        return a[sortBy] - b[sortBy];
      }
    } else {
      if (type === SORTING_TYPES.string) {
        return a[sortBy].toString() < b[sortBy].toString();
      } else if (type === SORTING_TYPES.number) {
        return b[sortBy] - a[sortBy];
      }
    }
  });
};
