import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { makeStyles, Grid } from '@material-ui/core';
import { AppRouteContext } from 'contexts';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
  },
}));

const PublicRoute = ({ route, ...rest }) => {
  const classes = useStyles();
  const {
    additional: { component: Component },
  } = rest;
  const params = useParams();
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);

  if (!breadcrumbs.length) {
    setAppRoute({ path: rest.path, params });
  }

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.wrapper}
    >
      <Component {...route} />
    </Grid>
  );
};

PublicRoute.propTypes = {
  route: PropTypes.shape({
    path: PropTypes.string,
    exact: PropTypes.bool,
    additional: PropTypes.shape({
      component: PropTypes.func.isRequired,
      protected: PropTypes.bool.isRequired,
      title: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PublicRoute;
