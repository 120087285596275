import {
  Box,
  fade,
  Divider,
  Grid,
  Grow,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import cx from 'classnames';
import PatientCreateModal from 'components/Patients/PatientCreateModal';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import { ReactComponent as PatientWatermark } from 'assets/icons/patient-watermark.svg';
import { ReactComponent as GenderWatermark } from 'assets/icons/gender-watermark.svg';
import { ReactComponent as AgeWatermark } from 'assets/icons/age-watermark.svg';
import { ReactComponent as PatientIcon } from 'assets/icons/patient-big.svg';
import { ReactComponent as GenderIcon } from 'assets/icons/gender-big.svg';
import { ReactComponent as AgeIcon } from 'assets/icons/age-big.svg';
import { AppRouteContext } from 'contexts';
import theme from 'config/theme';
import emptyDataIcon from 'assets/placeholders/emptyState.svg';
import { useDispatch, useSelector } from 'react-redux';
import VirtualizedTable from '../Table/VirtualizedTable';
import TablePlaceholder from '../Shared/TablePlaceholder';
import assessmentsPlaceholderImage from '../../assets/placeholders/orders.svg';
import { routes } from '../../config/routes';
import getFakeDataList from '../Shared/FakeDataGenerator';
import { FAKE_ASSESSMENT } from '../Assessments/constants';
import Charts from './Charts';
import {
  getMetrics,
  markHelpSeekingBehaviorAlertAsReviewed,
} from '../../redux_store/reducer/reducers/chartsReducer';
import { REQUEST_STATUS } from '../../redux_store/reducer/reducers/userReducer/constants';
import {
  ANIMATION_TIMEOUT,
  assessmentsAlertsColumns,
  healthSeekingBehaviorColumns,
} from './constants';

const useStyles = makeStyles(() => ({
  paper: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(3),
    },
  },
  centerPaper: {
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(0),
    },
  },
  gridItem: {
    border: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 24,
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    marginRight: theme.spacing(3),
    minHeight: 440,
    [theme.breakpoints.down('md')]: {
      marginRight: theme.spacing(0),
    },
  },
  fullWidthTable: {
    marginRight: 0,
  },
  addNewButton: {
    height: '100%',
    marginRight: theme.spacing(2),
  },
  infoBox: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
  },
  patientBox: {
    backgroundColor: theme.colors.lightBlue,
  },
  genderBox: {
    backgroundColor: theme.colors.blue5,
  },
  averageAgeBox: {
    backgroundColor: theme.colors.blue4,
  },
  watermark: {
    position: 'absolute',
    right: '-7%',
    top: '-16%',
  },
  genderWatermark: {
    top: 0,
  },
  infoText: {
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: theme.palette.common.white,
  },
  icon: {
    width: 58,
    marginRight: 2,
  },
  icon2: {
    width: 62,
    marginLeft: -2,
  },
  infoNumber: {
    maxHeight: 44,
    marginTop: -2,
    marginLeft: -1,
    fontSize: 36,
    fontWeight: 400,
    color: theme.palette.common.white,
  },
  divider: {
    width: '100%',
    margin: theme.spacing(4, 0),
  },
  chartTitle: {
    fontSize: 13,
    fontWeight: 700,
    letterSpacing: 2,
    textTransform: 'uppercase',
    color: theme.colors.blue2,
  },
  emptyDataWidget: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flexDirection: 'column',
  },
  emptyDataTitle: {
    marginTop: theme.spacing(2),
    color: fade(theme.colors.blue2, 0.5),
  },
}));


const DashboardComponent = () => {
  const { setAppRoute } = useContext(AppRouteContext);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation(['btn', 'charts', 'errors', 'notifications']);
  const {
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const { assessmentAlerts, metrics, status, helpSeekingBehaviorAlerts } = useSelector(
    (state) => state.charts,
  );
  const [openPatientModal, togglePatientModal] = useState(false);
  const averageAge = get(metrics, 'averageAge');
  const femalePercentage = `${Math.round(
    get(metrics, 'femalePercentage', 0) * 100,
  )}%`;
  const numberOfPatients = get(metrics, 'numberOfPatients');
  const chartData = get(metrics, 'chartData');

  useEffect(() => {
    dispatch(getMetrics(currentAccount.uuid));
  }, []);

  if (status === REQUEST_STATUS.failed) {
    return (
      <ContentWrapper>
        <div className={classes.emptyDataWidget}>
          <img alt='' src={emptyDataIcon} />
          <Typography className={classes.emptyDataTitle}>
            Error loading data
          </Typography>
        </div>
      </ContentWrapper>
    );
  }

  const redirectToAssessmentReport = ({
                                        uuid,
                                        accountUUID,
                                        patientUUID,
                                        surveyName,
                                      }) => {
    setAppRoute({
      path: routes.patientAssessmentReport.path,
      params: {
        accountUuid: accountUUID,
        patientUuid: patientUUID,
        assessmentUuid: uuid,
        status: 'pendingReview',
      },
      title: `${surveyName} ${t('titles:report')}`,
    });
  };
  const isTableDataLoading = status !== REQUEST_STATUS.succeeded || !assessmentAlerts || !helpSeekingBehaviorAlerts;
  const FAKE_ASSESSMENT_LIST = getFakeDataList(FAKE_ASSESSMENT);
  return (
    <>
      <PatientCreateModal
        open={openPatientModal}
        accountUuid={currentAccount.uuid}
        handleClose={() => togglePatientModal(false)}
        reloadData={() => null}
      />
      <ContentWrapper
        titleText={t('titles:dashboard')}
        actions={
          <RoundedButton
            size='small'
            color='primary'
            variant='outlined'
            className={classes.addNewButton}
            onClick={() => togglePatientModal(true)}
            disableTextUppercase={true}
            style={{
              height: 49
            }}
          >
            {t('btn:addPatient')}
          </RoundedButton>
        }
      >
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <div className={classes.gridItem}>
                <Typography className={classes.chartTitle}>
                  {t('tables:assessmentsAlerts')}
                </Typography>

                {!isTableDataLoading && (
                  <VirtualizedTable
                    fakeRows={FAKE_ASSESSMENT_LIST}
                    rowCount={assessmentAlerts.length}
                    rows={assessmentAlerts}
                    columns={assessmentsAlertsColumns}
                    onRowClick={redirectToAssessmentReport}
                    FetchProps={{
                      status,
                      sort: {},
                      filter: {},
                      onSort: () => {
                      },
                    }}
                    onDataLoading={isTableDataLoading}
                    currentAccountPermissions={currentAccountPermissions}
                    hideContextMenu={true}
                    placeholder={
                      <TablePlaceholder
                        image={assessmentsPlaceholderImage}
                        itemsNotFoundText={t('placeholders:noItemsFound', {
                          item: t('titles:assessments'),
                        })}
                        message={t(
                          'placeholders:reviseFiltersOrCreateYourFirstItem',
                          {
                            item: t('titles:assessment'),
                          },
                        )}
                      />
                    }
                  />
                )}
              </div>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={4}>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={12}
                className={classes.paper}
              >
                <Grow in timeout={ANIMATION_TIMEOUT}>
                  <Box className={cx(classes.infoBox, classes.patientBox)}>
                    <Box p={4} display='flex'>
                      <div className={classes.icon2}>
                        <PatientIcon
                          style={{ width: '100%', height: '100%' }}
                        />
                      </div>
                      <Box pl={4}>
                        <Typography className={classes.infoNumber}>
                          {numberOfPatients}
                        </Typography>
                        <Typography className={classes.infoText}>
                          {t('charts:numberOfPatients')}
                        </Typography>
                      </Box>
                    </Box>
                    <PatientWatermark className={classes.watermark} />
                  </Box>
                </Grow>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={12}
                className={cx(classes.paper, classes.centerPaper)}
              >
                <Grow in timeout={ANIMATION_TIMEOUT}>
                  <Box className={cx(classes.infoBox, classes.genderBox)}>
                    <Box p={4} display='flex'>
                      <div className={classes.icon}>
                        <GenderIcon style={{ width: '100%', height: '100%' }} />
                      </div>
                      <Box pl={4}>
                        <Typography className={classes.infoNumber}>
                          {femalePercentage}
                        </Typography>
                        <Typography className={classes.infoText}>
                          {t('charts:genderFemale')}
                        </Typography>
                      </Box>
                    </Box>
                    <GenderWatermark
                      className={cx(classes.watermark, classes.genderWatermark)}
                    />
                  </Box>
                </Grow>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grow in timeout={ANIMATION_TIMEOUT}>
                  <Box className={cx(classes.infoBox, classes.averageAgeBox)}>
                    <Box p={4} display='flex'>
                      <div className={classes.icon}>
                        <AgeIcon style={{ width: '100%', height: '100%' }} />
                      </div>
                      <Box pl={4}>
                        <Typography className={classes.infoNumber}>
                          {averageAge}
                        </Typography>
                        <Typography className={classes.infoText}>
                          {t('charts:averageAge')}
                        </Typography>
                      </Box>
                    </Box>
                    <AgeWatermark className={classes.watermark} />
                  </Box>
                </Grow>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box p={2}>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div className={cx(classes.gridItem, classes.fullWidthTable)}>
                <Typography className={classes.chartTitle}>
                  {t('tables:healthSeekingBehavior')}
                </Typography>

                {!isTableDataLoading && (
                  <VirtualizedTable
                    fakeRows={FAKE_ASSESSMENT_LIST}
                    rowCount={helpSeekingBehaviorAlerts.length}
                    rows={helpSeekingBehaviorAlerts}
                    columns={healthSeekingBehaviorColumns}
                    FetchProps={{
                      status,
                      sort: {},
                      filter: {},
                      onSort: () => {
                      },
                    }}
                    onEndCheckboxClick={(data) => {
                      dispatch(markHelpSeekingBehaviorAlertAsReviewed({
                        accountUUID: currentAccount.uuid,
                        surveyUUID: data.uuid,
                      }));
                    }}
                    hideContextMenu={true}
                    endCheckboxLabel={t('tables:reviewed')}
                    onDataLoading={isTableDataLoading}
                    currentAccountPermissions={currentAccountPermissions}
                    placeholder={
                      <TablePlaceholder
                        image={assessmentsPlaceholderImage}
                        itemsNotFoundText={t('placeholders:noItemsFound', {
                          item: t('titles:assessments'),
                        })}
                        message={t(
                          'placeholders:reviseFiltersOrCreateYourFirstItem',
                          {
                            item: t('titles:assessment'),
                          },
                        )}
                      />
                    }
                  />
                )}
              </div>
            </Grid>
          </Grid>
          <Divider className={classes.divider} />
          <Charts chartData={chartData} />
        </Box>
      </ContentWrapper>
    </>
  );
};

export default DashboardComponent;
